import React from 'react';
import { Box, Button, Typography } from '@mui/material';

interface DatabricksHistoryProps {
  history: ITransformationJobRuns[] | null;
  onDelete: (jobRunId: string) => void;
}

export const DatabricksHistory = ({ history, onDelete }: DatabricksHistoryProps): JSX.Element => {
  return (
    <Box
      sx={{
        maxHeight: '100%',
        overflowY: 'auto',
        p: 2,
      }}
    >
      {history &&
        Array.isArray(history) &&
        history.map((entry, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: index < history.length - 1 ? '1px solid #ccc' : 'none',
              borderRadius: '4px',
              mb: 1,
              p: 1,
              overflow: 'hidden',
            }}
          >
            <Box sx={{ flex: 1, textAlign: 'left', overflow: 'hidden' }}>
              <Typography>
                <strong>processing_id:</strong> {entry.processingId}
              </Typography>
              <Typography>
                <strong>databricksJobRunId:</strong> {entry.id}
              </Typography>
              <Typography>
                <strong>lifecycleState:</strong> {entry.lifecycleState}
              </Typography>
              <Typography>
                <strong>resultState:</strong> {entry.resultState}
              </Typography>
            </Box>

            <Box sx={{ flexShrink: 0, ml: 2 }}>
              <Button variant="outlined" onClick={() => onDelete(entry.processingId)}>
                Delete Entry
              </Button>
            </Box>
          </Box>
        ))}
    </Box>
  );
};
