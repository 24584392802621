import dayjs, { Dayjs } from 'dayjs';
import { default as isBase64Validator } from 'validator/lib/isBase64';
import { default as isEmailValidator } from 'validator/lib/isEmail';
import isFQDN from 'validator/lib/isFQDN';
import isJSON from 'validator/lib/isJSON';
import isURL from 'validator/lib/isURL';
import isUUID from 'validator/lib/isUUID';

/**
 * Return true if the value is Base64 formatted, eg. "c2Vla291dC5jb20=", false otherwise
 */
export function isBase64(value: unknown): boolean {
  return typeof value === 'string' && isBase64Validator(value);
}

/**
 * Return true if the value is a domain, eg. "seekout.com", false otherwise
 */
export function isDomain(value: unknown): boolean {
  return typeof value === 'string' && isFQDN(value);
}

/**
 * Return true if the value is a url, eg. "https://seekout.com", false otherwise
 */
export function isUrl(value: unknown): boolean {
  return typeof value === 'string' && isURL(value, { require_protocol: true });
}

/**
 * Return true if the value is an email, eg. "william@seekout.com", false otherwise
 */
export function isEmail(value: unknown): boolean {
  return typeof value === 'string' && isEmailValidator(value);
}

/**
 * Return true if the value is a GUID, eg. '940a7095-45bb-47ab-a1d1-7af4f07e062d', false otherwise
 */
export function isGuid(value: unknown): boolean {
  return typeof value === 'string' && isUUID(value);
}

/**
 * Return true if the value is valid JSON, excluding primitives, false otherwise
 */
export function isJson(value: unknown): boolean {
  return typeof value === 'string' && isJSON(value);
}

/**
 * Return the Dayjs equivalent of this value. Check isValid() to determine if it was actually a date.
 */
function asDate(value: unknown): Dayjs {
  if (value) {
    if (value instanceof Date || dayjs.isDayjs(value)) {
      return dayjs(value);
    }

    // Regular number can be interpreted as a date, so limit to ~1999 to ~2049
    const isValidNumber = (x: number) => x > 900000000000 && x < 2500000000000;

    if (typeof value === 'number' && isValidNumber(value)) {
      return dayjs(value);
    }

    if (typeof value === 'string') {
      // Is the string a string version of a normal number?
      // Note: Don't use parseInt() for the first test, as it tries to handle non-numbers nicely
      if (/^\d+$/.test(value)) {
        const numValue = parseInt(value);
        if (isValidNumber(numValue)) {
          return dayjs(numValue);
        }
      } else {
        const date = dayjs(value);
        const year = date.year();

        // Strings that end with numbers, can be interpreted as dates
        // Limit to dates in the same range as numbers, but after DayJS default year 2001
        if (!isNaN(year) && year > 2001 && year < 2050) {
          return date;
        }
      }
    }
  }

  return dayjs('');
}

/**
 * If possible, convert start and end value to time delta string, eg. '01:02:23'. Else return undefined.
 * @param start Start time
 * @param end End time
 * @returns Time delta string, or undefined
 */
export function asTimeDeltaDisplayString(start: unknown, end: unknown): string | undefined {
  const startDate = asDate(start);
  const endDate = asDate(end);
  if (startDate.isValid() && endDate.isValid()) {
    // Find time delta in hh:mm:ss
    const secondsInHour = 60 * 60;
    const secondsInMinute = 60;
    let totalSecondsDelta = endDate.diff(startDate, 'second');
    const hoursDelta = `${Math.floor(totalSecondsDelta / secondsInHour)}`.padStart(2, '0');
    totalSecondsDelta %= secondsInHour;
    const minutesDelta = `${Math.floor(totalSecondsDelta / secondsInMinute)}`.padStart(2, '0');
    totalSecondsDelta %= secondsInMinute;
    const secondsDelta = `${totalSecondsDelta}`.padStart(2, '0');
    return `${hoursDelta}:${minutesDelta}:${secondsDelta}`;
  }
}

/**
 * If possible, convert the value to an ISO Date string, eg. '2021-12-03T18:44:20.683Z'. Else return undefined.
 */
export function asDateISOString(value: unknown): string | undefined {
  const date = asDate(value);
  return date.isValid() ? date.toISOString() : undefined;
}

/**
 * If possible, convert the value to an end of day. Else return undefined.
 */
export function asEndOfDay(value: unknown): number | undefined {
  const date = asDate(value);
  return date.isValid() ? date.endOf('day').valueOf() : undefined;
}

/**
 * If possible, convert the value to a clean email string
 */
export function asCleanEmail(value?: string): string | undefined {
  // Unactivated users have their emails stored with encoded '@'
  return value?.replace('&#64;', '@');
}

/**
 * Return a combined version of the first and last names
 */
export function asFullName(firstName?: string, lastName?: string): string | undefined {
  if (firstName || lastName) {
    if (!firstName) return lastName;
    else if (!lastName) return firstName;
    else return `${firstName} ${lastName}`;
  }
}

/**
 * Split the fullName into first and last and return the components
 * This preserves the (poor) name splitting logic used in the customer-facing account creation
 * Anything before the first space character is the first name
 */
export function asSplitName(fullName?: string): { firstName?: string; lastName?: string } {
  const names = fullName?.split(' ');
  return {
    firstName: names?.[0],
    lastName: names && names.length > 1 ? names.slice(1).join(' ') : undefined,
  };
}

/**
 * Return true if the value is a string in the desired format, false otherwise
 */
export function isValidString(format: StringFormat, value: unknown): boolean {
  if (typeof value !== 'string') return false;

  switch (format) {
    case 'base64':
      return isBase64(value);
    case 'domain':
      return isDomain(value);
    case 'domains':
      return value.split(',').every((d) => isDomain(d.trim()));
    case 'email':
      return isEmail(value);
    case 'guid':
      return isGuid(value);
    case 'url':
      return isUrl(value);
    case 'boolean':
      return ['true', 'false'].includes(value.toLowerCase());
    default:
      return !!value.trim();
  }
}

/**
 * Return a displayable string version of the value
 */
export function asDisplayString(value: unknown): string {
  if (value == undefined) {
    return '';
  }

  const date = asDate(value);
  if (date.isValid()) {
    // Heuristic for only date, no time
    if (date.millisecond() === 0 && date.second() === 0) {
      return date.format('ddd MMM DD YYYY');
    }

    return date.format('ddd MMM DD YYYY HH:mm:ss');
  }

  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.map((x) => asDisplayString(x)).join(', ');
    }

    return Object.entries(value)
      .map(([key, value]) => {
        // Include only the first part of guids to save table space
        let displayValue = asDisplayString(value);
        if (isGuid(displayValue)) displayValue = `${displayValue}`.slice(0, 8);
        return `${key}: ${displayValue}`;
      })
      .join(' / ');
  }

  return `${value}`;
}

/**
 * Return a multi-line displayable version of the value
 */
export function asTooltipStrings(value: unknown): string[] {
  if (value == undefined || value === '') {
    return [];
  }

  const date = asDateISOString(value);
  if (date) {
    return [date];
  }

  if (typeof value === 'object') {
    return Object.entries(value).map(([key, value]) => `${key}: ${asDisplayString(value)}`);
  }

  return [`${value}`];
}

/**
 * Return a displayable version of the type of the value
 */
export function getDisplayType(value: unknown): string {
  const date = asDate(value);
  if (date.isValid()) {
    return 'Date';
  }

  if (isGuid(value)) {
    return 'Guid';
  }

  if (isEmail(value)) {
    return 'Email';
  }

  if (isUrl(value)) {
    return 'Url';
  }

  if (isDomain(value)) {
    return 'Domain';
  }

  return value == undefined ? '' : typeof value;
}

/**
 * Returns a path by replacing all slashes with tildes
 */
export function getIntegrationPath(path: string): string {
  return path.replace(/\//g, '~');
}
