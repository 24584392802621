import React from 'react';
import { Box, Button, TextField, Select, MenuItem, Typography } from '@mui/material';
import { EntityType } from 'utils/enums';

interface CommonEntityViewerProps {
  connectionId?: string;
  entityId: string;
  setEntityId: (val: string) => void;
  entityType: string;
  setEntityType: (val: string) => void;
  jsonData: Record<string, unknown>[] | string | null;
  onFetchEntity: (
    connectionId: string,
    entityId: string,
    entityType: string,
    domain?: string
  ) => void;
  loadingMessage: string;
  domain?: string;
}

export const CommonEntityViewer = ({
  connectionId,
  entityId,
  setEntityId,
  entityType,
  setEntityType,
  jsonData,
  onFetchEntity,
  loadingMessage,
  domain,
}: CommonEntityViewerProps): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
        <TextField
          label="Entity ID"
          variant="outlined"
          value={entityId}
          onChange={(e) => setEntityId(e.target.value)}
          sx={{ flex: 1, maxWidth: '25%' }}
        />

        <Select
          value={entityType}
          onChange={(e) => setEntityType(e.target.value)}
          displayEmpty
          sx={{ flex: 1, maxWidth: '25%' }}
        >
          <MenuItem value="">
            <em>Select Entity Type</em>
          </MenuItem>
          {EntityType.map((type) => (
            <MenuItem key={type.value} value={domain ? type.text : type.value}>
              {type.text}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (connectionId && entityId && entityType) {
              onFetchEntity(connectionId, entityId, entityType, domain);
            }
          }}
        >
          Fetch
        </Button>
      </Box>

      <Box
        sx={{
          p: 2,
          border: '1px solid #ddd',
          borderRadius: '4px',
          backgroundColor: '#f9f9f9',
          mt: 2,
        }}
      >
        {jsonData === null ? (
          <Typography variant="body2" sx={{ color: '#555', height: '50vh', overflow: 'auto' }}>
            {loadingMessage}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              maxHeight: '50vh',
              overflow: 'auto',
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              textAlign: 'left',
            }}
          >
            {JSON.stringify(jsonData, null, 2)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
