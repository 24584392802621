import React from 'react';
import { Box, Typography } from '@mui/material';
import cronParser from 'cron-parser';

const StatusIndicator = ({
  label,
  value,
}: {
  label: string;
  value: string | number | boolean;
}): JSX.Element => (
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 0 }}>
    <Typography>{label}:</Typography>
    <Typography variant="body1" sx={{ ml: 1 }}>
      {value}
    </Typography>
  </Box>
);

interface Props {
  extractionStatus?: IExtractionStatus | null;
  currentTabIndex: number;
  transformationDriverProcessFlag: boolean;
}

export const ConnectionStatusDetails = ({
  extractionStatus,
  currentTabIndex,
  transformationDriverProcessFlag,
}: Props): JSX.Element => {
  const formatDate = (date: string) => (date ? new Date(date).toLocaleString() : 'N/A');
  const formatBoolean = (bool: boolean | null) => (bool !== null ? (bool ? 'Yes' : 'No') : 'N/A');
  const healthStatus = getHealth(extractionStatus ?? null);
  const healthText = healthStatus === 'green' ? 'Healthy' : 'Unhealthy';

  function getHealth(extractionStatus: IExtractionStatus | null) {
    if (!extractionStatus) return 'red';
    if (!extractionStatus.isActive || extractionStatus.circuitStateBroken) return 'red';
    if (!extractionStatus.scheduleCrontab) return 'red';

    const interval = cronParser.parseExpression(extractionStatus.scheduleCrontab);
    const nextScheduledTime = interval.next().getTime();
    const previousScheduledTime = interval.prev().getTime();

    if (!extractionStatus.lastOccurenceTime && !extractionStatus.lastTransformation) {
      return 'red';
    }

    const lastOccurrenceTime = extractionStatus.lastOccurenceTime
      ? new Date(extractionStatus.lastOccurenceTime).getTime()
      : 0;
    const lastTransformationTime = extractionStatus.lastTransformation
      ? new Date(extractionStatus.lastTransformation).getTime()
      : 0;
    if (
      (lastOccurrenceTime < previousScheduledTime && lastOccurrenceTime > nextScheduledTime) ||
      (lastTransformationTime < previousScheduledTime && lastTransformationTime > nextScheduledTime)
    ) {
      return 'red';
    }
    return 'green';
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Box
          sx={{
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundColor: healthStatus,
            mr: 1,
            ml: 2,
          }}
        />
        <Typography variant="h6">{healthText}</Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
        <StatusIndicator
          label="Last Extraction"
          value={
            extractionStatus?.lastOccurenceTime
              ? formatDate(extractionStatus.lastOccurenceTime)
              : 'N/A'
          }
        />
        <StatusIndicator
          label="Last Transformation"
          value={
            extractionStatus?.lastTransformation
              ? formatDate(extractionStatus.lastTransformation)
              : 'N/A'
          }
        />
        <StatusIndicator
          label="Active"
          value={
            extractionStatus?.isActive !== undefined
              ? formatBoolean(extractionStatus.isActive)
              : 'N/A'
          }
        />
        <StatusIndicator
          label="Circuit State Broken"
          value={
            extractionStatus?.circuitStateBroken !== undefined
              ? formatBoolean(extractionStatus.circuitStateBroken)
              : 'N/A'
          }
        />
        {currentTabIndex === 2 && (
          <StatusIndicator
            label="Driver Process Flag"
            value={
              extractionStatus?.circuitStateBroken !== undefined
                ? formatBoolean(transformationDriverProcessFlag)
                : 'N/A'
            }
          />
        )}
      </Box>
    </Box>
  );
};
