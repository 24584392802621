import React from 'react';
import { Box, Typography, Button } from '@mui/material';

interface ReportItemProps {
  report: {
    id: string;
    report_arguments: { report_name: string; arguments: Record<string, unknown> };
    processing_state: string;
    connection_id: string;
    report_urls?: string[];
  };
  index: number;
  totalReports: number;
  onRepair: (connectionId: string, reportId: string) => void;
}

export const ReportItem = ({
  report,
  index,
  totalReports,
  onRepair,
}: ReportItemProps): JSX.Element => {
  const isComplete = report.processing_state === 'Complete';
  const isFailed = report.processing_state === 'Failed';
  const buttonLabel = isFailed ? 'Repair' : 'Fetch Report';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderBottom: index < totalReports - 1 ? '1px solid #ccc' : 'none',
        pb: 1,
        mb: 1,
      }}
    >
      <Typography>
        <strong>ID:</strong> {report.id || 'N/A'}
      </Typography>
      <Typography>
        <strong>Report Name:</strong> {report.report_arguments?.report_name || 'N/A'}
      </Typography>
      <Typography>
        <strong>Processing State:</strong> {report.processing_state || 'N/A'}
      </Typography>
      <Typography>
        <strong>Report Inputs:</strong>
        <pre>{JSON.stringify(report.report_arguments?.arguments, null, 2)}</pre>
      </Typography>
      <Button
        variant="outlined"
        sx={{ mt: 1, width: '30%', alignSelf: 'center' }}
        disabled={!isComplete && !isFailed}
        onClick={() => {
          if (isFailed) {
            onRepair(report.connection_id, report.id);
          } else if (isComplete && report.report_urls) {
            const url = Array.isArray(report.report_urls)
              ? report.report_urls[0]
              : report.report_urls;
            if (url) {
              window.open(url, '_blank');
            }
          }
        }}
      >
        {buttonLabel}
      </Button>
    </Box>
  );
};
