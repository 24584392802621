import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { ReportItem } from 'components/integrations/ReportItem';

interface ReportListProps {
  reports: string | null;
  onRepair: (connectionId: string, reportId: string) => void;
}

const NoReportsDisplay = () => (
  <Typography
    variant="body2"
    sx={{
      color: '#555',
      height: '50vh',
      overflow: 'auto',
      border: '1px solid #ccc',
      width: '95%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    No reports found
  </Typography>
);

export const ReportList = ({ reports, onRepair }: ReportListProps): JSX.Element => {
  const parsedReports = useMemo(() => {
    try {
      return reports ? JSON.parse(reports) : [];
    } catch {
      return [];
    }
  }, [reports]);

  if (!Array.isArray(parsedReports) || parsedReports.length === 0) {
    return <NoReportsDisplay />;
  }

  return (
    <Box
      sx={{
        maxHeight: '50vh',
        overflow: 'auto',
        whiteSpace: 'pre-wrap',
        fontFamily: 'monospace',
        textAlign: 'left',
        border: '1px solid #ccc',
        p: 2,
        width: '90%',
      }}
    >
      {parsedReports.map((report, index) => (
        <ReportItem
          key={index}
          report={report}
          index={index}
          totalReports={parsedReports.length}
          onRepair={onRepair}
        />
      ))}
    </Box>
  );
};
