import React from 'react';
import { Box, TextField, Typography, Tabs, Tab, Link, Alert, Button } from '@mui/material';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ConnectionStatusDetails } from 'components/integrations/ConnectionStatusDetails';
import { ViewFrame } from 'features/frame/ViewFrame';
import { NoPermission } from 'components/NoPermission';
import { Privilege } from 'types/enums';
import { Scheduler } from 'features/system/Scheduler';
import { Extraction } from 'features/system/Extraction';
import { Driver } from 'features/system/Driver';
import { Auditor } from 'features/system/Auditor';
import { Distribution } from 'features/system/Distribution';
import { Reporting } from 'features/system/Reporting';

interface CommonViewFrameProps {
  operations: Operation[];
  isProcessing: boolean;
  setAction: (action: string) => void;
  setOperations: (operations: Operation[]) => void;
  adminPrivilege: Privilege;
  searchTerm: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filteredConnections: () => IATSConnection[];
  handleRowClick: (rowId: string) => void;
  selectedConnectionId: string | null;
  extractionStatus: IExtractionStatus | null;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  transformationDriverProcessFlag: boolean;
  displayTabs: string[];
  popupOpen: boolean;
  setPopupOpen: (open: boolean) => void;
  popupMessage: string;
  contentMessage: string;
  initializeV2Connections: (connId: string) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `horizontal-tab-${index}`,
    'aria-controls': `horizontal-tabpanel-${index}`,
  };
}

export const CommonViewFrame: React.FC<CommonViewFrameProps> = ({
  operations,
  isProcessing,
  contentMessage,
  setAction,
  setOperations,
  adminPrivilege,
  searchTerm,
  handleSearchChange,
  filteredConnections,
  handleRowClick,
  selectedConnectionId,
  extractionStatus,
  currentIndex,
  setCurrentIndex,
  transformationDriverProcessFlag,
  displayTabs,
  popupOpen,
  setPopupOpen,
  popupMessage,
  initializeV2Connections,
}) => {
  return (
    <ViewFrame
      contentLoader={{
        message: contentMessage || '',
        contentOperations: operations,
        forceClose: !isProcessing,
        onClose: () => {
          setAction('');
          setOperations([]);
        },
      }}
    >
      {adminPrivilege !== Privilege.All ? (
        <NoPermission />
      ) : (
        <Box sx={{ display: 'flex', height: '100%', flexDirection: 'row' }}>
          <Box sx={{ width: '25%', pt: 2, ml: 2 }}>
            <Box sx={{ display: 'flex' }}>
              <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Enter Connection Id or Domain or ATS Type"
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ display: 'flex', height: '85%' }}>
              <StandardGrid
                dataSet={filteredConnections()}
                showSearchBar={false}
                getRowId={(x) => `${x.id}*${x.userId}`}
                onRowClick={(row) => handleRowClick(row.id)}
                cols={[
                  {
                    name: 'Domain',
                    description: '',
                    valueProperty: 'orgDomain',
                  },
                  {
                    name: 'Connection Id',
                    description: '',
                    valueProperty: 'id',
                  },
                  {
                    name: 'ATS Type',
                    description: '',
                    valueProperty: 'atsType',
                  },
                ]}
              />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {popupOpen && (
              <Alert
                severity="success"
                onClose={() => setPopupOpen(false)}
                sx={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 9999,
                  minWidth: '300px',
                  boxShadow: 4,
                }}
              >
                {popupMessage}
              </Alert>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                mt: 2,
                gap: 4,
              }}
            >
              <ConnectionStatusDetails
                extractionStatus={extractionStatus}
                currentTabIndex={currentIndex}
                transformationDriverProcessFlag={transformationDriverProcessFlag}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <Typography variant="h6" sx={{ whiteSpace: 'normal' }}>
                  {filteredConnections().find((conn) => conn.id === selectedConnectionId)
                    ?.orgDomain ?? 'Seekout'}{' '}
                  - {selectedConnectionId ?? 'N/A'}
                </Typography>
                <Box
                  sx={{ display: 'none', flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}
                >
                  <Typography>
                    <Link href="" target="_blank" rel="noopener">
                      Databricks Job
                    </Link>
                  </Typography>
                  <Typography>
                    <Link href="" target="_blank" rel="noopener">
                      Driver Job
                    </Link>
                  </Typography>
                  <Typography>
                    <Link href="" target="_blank" rel="noopener">
                      Extraction Job
                    </Link>
                  </Typography>
                  <Typography>
                    <Link href="" target="_blank" rel="noopener">
                      Service Bus Topic
                    </Link>
                  </Typography>
                </Box>
              </Box>
              {selectedConnectionId && (
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => initializeV2Connections(selectedConnectionId ?? '')}
                  >
                    Initialize Connection
                  </Button>
                </Box>
              )}
            </Box>

            {selectedConnectionId && (
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                  <Box sx={{ display: 'flex' }}>
                    <Tabs
                      sx={{
                        height: '100%',
                        maxWidth: '100%',
                        color: 'White',
                      }}
                      orientation="horizontal"
                      variant="standard"
                      value={currentIndex}
                      onChange={(_, value) => {
                        setCurrentIndex(value);
                      }}
                      aria-label="lookup tabs"
                      textColor="primary"
                      indicatorColor="primary"
                    >
                      {displayTabs.map((name, index) => (
                        <Tab
                          key={name}
                          label={name}
                          sx={{ padding: (theme) => theme.spacing(2), overflowWrap: 'anywhere' }}
                          {...a11yProps(index)}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CustomTabPanel value={currentIndex} index={0}>
                    <Scheduler connectionId={selectedConnectionId ?? undefined}></Scheduler>
                  </CustomTabPanel>
                  <CustomTabPanel value={currentIndex} index={1}>
                    <Extraction connectionId={selectedConnectionId ?? undefined}></Extraction>
                  </CustomTabPanel>
                  <CustomTabPanel value={currentIndex} index={2}>
                    <Driver
                      connectionId={selectedConnectionId ?? undefined}
                      atsType={
                        filteredConnections().find((conn) => conn.id === selectedConnectionId)
                          ?.atsType
                      }
                    ></Driver>
                  </CustomTabPanel>
                  <CustomTabPanel value={currentIndex} index={3}>
                    <Auditor connectionId={selectedConnectionId ?? undefined}></Auditor>
                  </CustomTabPanel>
                  <CustomTabPanel value={currentIndex} index={4}>
                    <Distribution
                      connectionId={selectedConnectionId ?? undefined}
                      domain={
                        filteredConnections().find((conn) => conn.id === selectedConnectionId)
                          ?.orgDomain ?? ''
                      }
                    ></Distribution>
                  </CustomTabPanel>
                  <CustomTabPanel value={currentIndex} index={5}>
                    <Reporting connectionId={selectedConnectionId ?? undefined}></Reporting>
                  </CustomTabPanel>
                  <CustomTabPanel value={currentIndex} index={6}>
                    <Typography>Work in progress</Typography>
                  </CustomTabPanel>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </ViewFrame>
  );
};
