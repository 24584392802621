import { useState, useEffect } from 'react';
import { Modal } from 'components/modals/Modal';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { Box, TextField } from '@mui/material';
import { ViewFrame } from 'features/frame/ViewFrame';
import { Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import {
  pauseScheduler,
  unpauseScheduler,
  setTransformationCircuitBreakFlag,
  configureCronTabSchedule,
  getExtractionSchedule,
} from 'api/apiThunks';
import { createFailedOperation } from 'utils/apiResult';

interface Props {
  connectionId?: string;
}

type SchedulerOpName =
  | 'fetchExtractionStatus'
  | 'pauseScheduler'
  | 'unpauseScheduler'
  | 'setTransformationCircuitBreak'
  | 'configureCronTabSchedule'
  | 'getScheduler'
  | undefined;

/**
 * The Scheduler view
 */
export const Scheduler = ({ connectionId }: Props): JSX.Element => {
  const [cronTabValue, setCronTabValue] = useState('');
  const [cronTabModalOpen, setCronTabModalOpen] = useState(false);
  const [cronTabMessage, setCronTabMessage] = useState('');
  const [confirmPauseUnPauseModalOpen, setConfirmPauseUnPauseModalOpen] = useState(false);
  const [schedulerPause, setSchedulerPause] = useState(false);
  const [confirmCircuitBreakerFix, setConfirmCircuitBreakerFix] = useState(false);
  const [action, setAction] = useState<string | undefined>();
  const [operationType, setOperationType] = useState<SchedulerOpName>();
  const [operations, setOperations] = useState<Operation[]>([]);
  const [jsonResult, setJsonResult] = useState('');

  let contentMessage;
  switch (operationType) {
    case 'pauseScheduler':
      contentMessage = 'Pausing scheduler';
      break;
    case 'unpauseScheduler':
      contentMessage = 'Resuming scheduler';
      break;
    case 'setTransformationCircuitBreak':
      contentMessage = 'Setting circuit break';
      break;
    case 'configureCronTabSchedule':
      contentMessage = 'Configuring Cron Tab';
      break;
    case 'getScheduler':
      contentMessage = 'Extracting scheduler JSON';
      break;
    default:
      contentMessage = '';
      break;
  }

  useEffect(() => {
    if (connectionId) {
      getExtractionScheduleJson(connectionId);
    }
  }, [connectionId]);

  const getExtractionScheduleJson = async (connectionId: string): Promise<void> => {
    setOperationType('getScheduler');
    setAction('');
    setOperations([{ status: 'loading' }]);

    try {
      const response = await getExtractionSchedule(connectionId);
      if (!response || Object.keys(response).length === 0) {
        setAction(undefined);
        setOperations([]);
        setJsonResult('');
        return;
      }
      if (response) {
        setAction(undefined);
        setOperations([]);
        setJsonResult(JSON.stringify(response, null, 2));
      }
    } catch (error) {
      setJsonResult('');
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([
        createFailedOperation(`Error in fetching Scheduler JSON for ${connectionId}: ${message}`),
      ]);
    }
  };

  const pauseUnPauseScheduler = async () => {
    setConfirmPauseUnPauseModalOpen(true);
  };

  const handleSchedulerPause = async () => {
    setOperationType('pauseScheduler');
    setAction('');
    setOperations([{ status: 'loading' }]);
    try {
      if (schedulerPause) {
        if (connectionId) {
          await unpauseScheduler(connectionId);
        }
      } else {
        if (connectionId) {
          await pauseScheduler(connectionId);
        }
      }
      setAction(undefined);
      setOperations([]);
      setSchedulerPause(!schedulerPause);
    } catch (error) {
      setJsonResult('');
      const message = error instanceof Error ? error.message : `${error}`;
      setAction(undefined);
      setOperations([createFailedOperation(`Error in pausing/resuming automation: ${message}`)]);
    }
  };

  const handleCircuitBreak = async () => {
    setOperationType('setTransformationCircuitBreak');
    setAction('');
    setOperations([{ status: 'loading' }]);
    try {
      if (connectionId) {
        await setTransformationCircuitBreakFlag(connectionId);
      }
      setAction(undefined);
      setOperations([]);
      setSchedulerPause(!schedulerPause);
      setConfirmCircuitBreakerFix(true);
    } catch (error) {
      setJsonResult('');
      const message = error instanceof Error ? error.message : `${error}`;
      setAction(undefined);
      setOperations([createFailedOperation(`Error in setting circuit break flag: ${message}`)]);
    }
  };

  const handleCronTabSubmit = async () => {
    const msg = cronTabValue ? 'Configuration saved successfully!' : 'Please enter data';
    if (cronTabValue) {
      setOperationType('configureCronTabSchedule');
      setAction('');
      setOperations([{ status: 'loading' }]);
      try {
        setCronTabModalOpen(false);
        if (connectionId) {
          await configureCronTabSchedule(connectionId, cronTabValue);
        }
        setAction(undefined);
        setOperations([]);
        setCronTabMessage(msg);
      } catch (error) {
        setJsonResult('');
        const message = error instanceof Error ? error.message : `${error}`;
        setAction(undefined);
        setOperations([
          createFailedOperation(
            `Error in configuring cron tab schedule for ${connectionId}: ${message}`
          ),
        ]);
      }
    }
  };

  const isProcessing = !!action || !!operations.length;

  return (
    <ViewFrame
      contentLoader={{
        message: contentMessage || '',
        contentOperations: operations,
        forceClose: !isProcessing,
        onClose: () => {
          setAction('');
          setOperations([]);
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
        <Box sx={{ ml: 2, mr: 2 }}>
          {confirmCircuitBreakerFix ? (
            <Alert
              severity="success"
              variant="outlined"
              color="warning"
              onClose={() => setConfirmCircuitBreakerFix(false)}
            >
              Circuit Break Successfully Fixed!
            </Alert>
          ) : null}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2, mt: 2 }}>
          <Box sx={{ display: 'flex', width: '75%', marginBottom: 2 }}>
            {!jsonResult ? (
              <Typography
                variant="body2"
                sx={{
                  color: '#555',
                  height: '50vh',
                  overflow: 'auto',
                  border: '1px solid #ccc',
                  width: '100%',
                }}
              >
                {'No Rows'}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  maxHeight: '50vh',
                  overflow: 'auto',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'monospace',
                  textAlign: 'left',
                  border: '1px solid #ccc',
                  width: '100%',
                }}
              >
                {jsonResult}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 1 }}>
            <Button variant="outlined" onClick={() => pauseUnPauseScheduler()}>
              {schedulerPause ? 'UnPause' : 'Pause'}
            </Button>
            <Button
              variant="outlined"
              sx={{ marginTop: 1 }}
              onClick={() => setCronTabModalOpen(true)}
            >
              Set Cron Tab
            </Button>
            <Button variant="outlined" onClick={() => handleCircuitBreak()} sx={{ marginTop: 1 }}>
              Fix Circuit Break
            </Button>
          </Box>
        </Box>
      </Box>
      <ConfirmModal
        open={confirmPauseUnPauseModalOpen}
        prompt={`Are you sure you want to ${schedulerPause ? 'UnPause' : 'Pause'} the scheduler?`}
        onClose={() => setConfirmPauseUnPauseModalOpen(false)}
        onAccept={() => {
          handleSchedulerPause();
        }}
      />
      <Modal
        title="Configure Cron Tab"
        open={cronTabModalOpen}
        onClose={() => setCronTabModalOpen(false)}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'sm',
          PaperProps: { sx: { display: 'flex', flexDirection: 'column', height: '100%' } },
        }}
        buttonText="Close"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
          <Typography>
            Cron expressions are used to define the timing for recurring tasks. Each field in a cron
            expression represents a specific unit of time, and the values in these fields determine
            when the task will run.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Cron Tab Format: * * * * * (minute, hour, day of month, month, day of week)
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Example: The value "*/60 * * * *" is a cron expression used to schedule tasks every 60
            mins.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 5 }}>
          <Box>
            <TextField
              label=""
              variant="outlined"
              value={cronTabValue}
              onChange={(e) => setCronTabValue(e.target.value)}
              placeholder="Enter value"
              sx={{ width: '100%' }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', ml: 2 }}>
            <Button variant="contained" onClick={handleCronTabSubmit}>
              Save
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Typography>{cronTabMessage}</Typography>
        </Box>
      </Modal>
    </ViewFrame>
  );
};
