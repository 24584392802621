/**
 * Generate a camel-cased entity type string for constructing query strings.
 *
 * @param entityType The type of entity being queried (e.g., "Candidates", "Jobs").
 * @param entityId A comma-separated string of entity IDs or a single entity ID.
 * @returns A string representing the query URL segment based on the entity type and ID(s).
 */
export function getCamelCaseEntityType(entityType: string, entityId: string): string {
  const isPlural = entityId.includes(',');
  let camelCaseEntityType = isPlural ? entityType : entityType.slice(0, -1);
  camelCaseEntityType = camelCaseEntityType.charAt(0).toLowerCase() + camelCaseEntityType.slice(1);
  if (isPlural) {
    const entityIds = entityId.split(',');
    const entityIdParams = entityIds
      .map((id) => `${camelCaseEntityType.slice(0, -1)}Ids=${id}`)
      .join('&');
    return `Get${entityType}?${entityIdParams}`;
  } else {
    return `Get${entityType.slice(0, -1)}?${camelCaseEntityType}Id=${entityId}`;
  }
}
