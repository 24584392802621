import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
} from '@mui/material';
import { ViewFrame } from 'features/frame/ViewFrame';
import { ReportList } from 'components/integrations/ReportList';
import { fetchReports, requestConsistencyReport, retryFailedReport } from 'api/apiThunks';
import { createFailedOperation } from 'utils/apiResult';

interface Props {
  connectionId?: string;
}

type ReportOpName = 'fetchReports' | 'requestConsistencyReport' | 'retryFailedReport' | undefined;

export const Reporting = ({ connectionId }: Props): JSX.Element => {
  const [saveConfigStatusMessage, setSaveConfigStatusMessage] = useState('');
  const [action, setAction] = useState<string | undefined>();
  const [operationType, setOperationType] = useState<ReportOpName>();
  const [operations, setOperations] = useState<Operation[]>([]);
  const [reportData, setReportData] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numJobs, setNumJobs] = useState<string | undefined>();
  const [jobIds, setJobIds] = useState<string>('');
  const [randomSamplingEnabled, setRandomSamplingEnabled] = useState(false);
  const [modalError, setModalError] = useState<string | null>(null);

  let contentMessage;
  switch (operationType) {
    case 'requestConsistencyReport':
      contentMessage = 'Requesting new data consistency report';
      break;
    case 'retryFailedReport':
      contentMessage = 'Retrying report';
      break;
    case 'fetchReports':
      contentMessage = 'Fetching reports';
      break;
    default:
      contentMessage = '';
      break;
  }

  useEffect(() => {
    if (connectionId) {
      fetchReportsData(connectionId);
    }
  }, [connectionId]);

  const fetchReportsData = async (connectionId: string): Promise<void> => {
    setOperationType('fetchReports');
    setAction('fetch');
    setOperations([{ status: 'loading' }]);
    try {
      const response = await fetchReports(connectionId);
      if (response) {
        setAction(undefined);
        setOperations([]);
        setReportData(JSON.stringify(response, null, 2));
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([
        createFailedOperation(`Error fetching Reports for ${connectionId}: ${message}`),
      ]);
    }
  };

  const openConsistencyReportModal = async () => {
    if (!connectionId) return;
    setAction('fetch');
    setOperations([{ status: 'loading' }]);
    try {
      const response = { randomSamplingEnabled: true, numJobs: 1, jobIds: '' };
      setNumJobs(response.numJobs.toString());
      setJobIds(response.jobIds);
      setRandomSamplingEnabled(response.randomSamplingEnabled);
      setIsModalOpen(true);
      setAction(undefined);
      setOperations([]);
    } catch (error) {
      setReportData('');
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([
        createFailedOperation(
          `Error fetching Report Configuration for ${connectionId}: ${message}`
        ),
      ]);
      setAction(undefined);
    }
  };

  const handleRepairReport = async (connectionId: string, reportId: string): Promise<void> => {
    setOperationType('retryFailedReport');
    setAction('fetch');
    setOperations([{ status: 'loading' }]);
    try {
      await retryFailedReport(connectionId, reportId);
      setAction(undefined);
      setOperations([]);
      fetchReportsData(connectionId);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([
        createFailedOperation(`Error retrying the report for ${connectionId}: ${message}`),
      ]);
      setAction(undefined);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalError(null);
  };

  const handleSubmitModal = async () => {
    if (!numJobs || parseInt(numJobs, 10) < 1) {
      setModalError('Num Jobs cannot be empty.');
      return;
    }

    setIsModalOpen(false);
    setModalError(null);

    if (connectionId) {
      setOperationType('requestConsistencyReport');
      setAction('fetch');
      setOperations([{ status: 'loading' }]);
      try {
        await requestConsistencyReport(connectionId, {
          numberOfJobs: parseInt(numJobs, 10),
          jobIds: jobIds?.split(',').map((id) => id.trim()),
          randomJobSamplingEnabled: randomSamplingEnabled,
        });
        setAction(undefined);
        setOperations([]);
        setSaveConfigStatusMessage('Consistency Report requested successfully');
      } catch (error) {
        const message = error instanceof Error ? error.message : `${error}`;
        setOperations([createFailedOperation(`Error requesting Consistency Report: ${message}`)]);
      }
    }
  };

  const isProcessing = !!action || !!operations.length;

  return (
    <ViewFrame
      contentLoader={{
        message: contentMessage || '',
        contentOperations: operations,
        forceClose: !isProcessing,
        onClose: () => {
          setAction('');
          setOperations([]);
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
        <Box sx={{ ml: 2, mr: 2 }}>
          {saveConfigStatusMessage && (
            <Alert
              severity="success"
              variant="outlined"
              color="info"
              onClose={() => setSaveConfigStatusMessage('')}
            >
              {saveConfigStatusMessage}
            </Alert>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
          <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
            <ReportList reports={reportData} onRepair={handleRepairReport} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              mr: 2,
              width: '25%',
            }}
          >
            <Button
              variant="outlined"
              sx={{ mb: 2, width: '100%' }}
              onClick={openConsistencyReportModal}
            >
              Consistency Report
            </Button>
            <Button
              variant="outlined"
              sx={{ width: '100%' }}
              onClick={() => fetchReportsData(connectionId || '')}
            >
              Other Reports
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={handleCloseModal} title="Consistency Report">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="consistency-report-title" variant="h6" component="h2">
            Consistency Report
          </Typography>
          <TextField
            label="Num Jobs"
            value={numJobs}
            onChange={(e) => setNumJobs(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Job IDs"
            value={jobIds}
            onChange={(e) => setJobIds(e.target.value)}
            fullWidth
            multiline
            rows={3}
            sx={{ mt: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={randomSamplingEnabled}
                onChange={(e) => setRandomSamplingEnabled(e.target.checked)}
              />
            }
            label="Random Job Sampling Enabled"
            sx={{ mt: 2 }}
          />
          {modalError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {modalError}
            </Alert>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
            <Button variant="contained" onClick={handleSubmitModal}>
              Submit
            </Button>
            <Button variant="outlined" onClick={handleCloseModal}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </ViewFrame>
  );
};
