import { Button, styled, SxProps, Theme, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';

const Input = styled('input')({ display: 'none' });
const Label = styled('label')({ alignSelf: 'start' });

interface Props extends FormFieldProps {
  accept?: string;
  sx?: SxProps<Theme>;
}

export const FileInput = ({ name, label, onChange, accept = 'image/*', sx }: Props) => {
  const [selectedFiles, setselectedFiles] = useState<string[]>([]);

  return (
    <Label htmlFor={name} sx={{ display: 'flex', alignItems: 'center', mt: 2, ...sx }}>
      <Input
        accept={accept}
        id={name}
        type="file"
        onChange={(e: { target: { files: FileList | null } }) => {
          const fileList = e.target.files;
          if (fileList) {
            const fileNames: string[] = [];
            for (let i = 0; i < fileList.length; i++) {
              fileNames.push(fileList[i].name);
            }
            setselectedFiles(fileNames);
          } else {
            setselectedFiles([]);
          }
          const event = { target: { files: e.target.files } };
          (onChange as InputChangeEvent)(event as ChangeEvent<HTMLInputElement>);
        }}
      />
      <Button component="span">{label}</Button>
      <Typography sx={{ ml: 1 }}>{selectedFiles.join(', ')}</Typography>
    </Label>
  );
};
