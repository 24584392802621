import React, { useState } from 'react';
import { ViewFrame } from 'features/frame/ViewFrame';
import { CommonEntityViewer } from 'components/integrations/CommonEntityViewer';
import { handleFetchEntityPayloadJSON } from 'api/apiThunks';
import { createFailedOperation } from 'utils/apiResult';

interface Props {
  connectionId?: string;
  domain?: string;
}

export const Distribution = ({ connectionId, domain }: Props): JSX.Element => {
  const [jsonData, setJsonData] = useState<Record<string, unknown>[] | string | null>(null);
  const [entityId, setEntityId] = useState<string>('');
  const [entityType, setEntityType] = useState<string>('');
  const [operations, setOperations] = useState<Operation[]>([]);
  const [action, setAction] = useState<string | undefined>();

  const fetchEntityPayloadJSON = async (
    connectionId: string,
    entityId: string,
    entityType: string,
    domain?: string
  ): Promise<void> => {
    setAction('fetch');
    setOperations([{ status: 'loading' }]);
    try {
      const response = await handleFetchEntityPayloadJSON(
        connectionId,
        entityId,
        entityType,
        domain || ''
      );
      setJsonData(response);
      setOperations([]);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setJsonData(null);
      setOperations([
        createFailedOperation(
          `Error in fetching Distribution Entity Details for ${connectionId}: ${message}`
        ),
      ]);
    }
  };

  return (
    <ViewFrame
      contentLoader={{
        message: action ? 'Fetching Distribution JSON' : '',
        contentOperations: operations,
        forceClose: !action && operations.length === 0,
        onClose: () => {
          setAction('');
          setOperations([]);
        },
      }}
    >
      <CommonEntityViewer
        connectionId={connectionId}
        entityId={entityId}
        setEntityId={setEntityId}
        entityType={entityType}
        setEntityType={setEntityType}
        jsonData={jsonData}
        onFetchEntity={fetchEntityPayloadJSON}
        loadingMessage="Entity payload JSON view"
        domain={domain}
      />
    </ViewFrame>
  );
};
