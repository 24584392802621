import { Box, Button, Typography } from '@mui/material';
import {
  deleteInternalField,
  getAllFieldData,
  getOrganizationTeams,
  saveNewInternalFields,
  updateInternalSavedField,
} from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { useEffect, useMemo, useState } from 'react';
import { getOperations } from 'utils/operable';

enum SavedFieldEnum {
  isFilterable = 'Is Filterable',
  isSensitive = 'Is Sensitive',
  teams = 'Teams',
  fieldName = 'Field Name',
  title = 'Title',
  collectionFormat = 'Collection Format',
  descriptionFormat = 'Description Format',
  listStyleType = 'List Style',
  sortField = 'Sort Field',
  sortDescending = 'Sort Descending',
  export = 'Export Format',
  fallbackDisplay = 'Fallback display',
  imageField = 'Image Url Field',
}

const listStyleTypeOptions = [
  {
    text: 'Timeline',
    value: 'timeline',
  },
  {
    text: 'Nested Timeline',
    value: 'nestedTimeline',
  },
  {
    text: 'Pills',
    value: 'pills',
  },
];

enum EditType {
  filters = 'Filters',
  format = 'Format',
  misc = 'Misc',
  listType = 'List Type',
  export = 'Export',
}

export const SensitivitySettings = () => {
  const org = useAppSelector(selectDerivedOrganization);
  const [editType, setEditType] = useState(EditType.filters);
  const viewOperations = [
    ...getOperations(org, 'configurableFields'),
    ...getOperations(org, 'internalSavedFields'),
    ...getOperations(org, 'teams'),
  ];
  const [configurableFieldssOp, internalSavedFieldsOp, teamsOp] = viewOperations;
  const { orgId, configurableFields = [], internalSavedFields = [], teams = [] } = org;

  const dispatch = useAppDispatch();

  const getAlteredConfigurableFields = (configFields: CustomSimpleField[]) => {
    const configurableFieldsData: CustomSimpleField[] = [];
    configFields?.forEach((field) => {
      if (!field.type.IsCollection || (field.type.IsCollection && !field.fields.length))
        return configurableFieldsData.push(field);
      configurableFieldsData.push(field);
      return field.fields.forEach((subfield) =>
        configurableFieldsData.push({
          ...subfield,
          name: `${field.name}:${subfield.name}`,
          isSubField: true,
        })
      );
    });
    return configurableFieldsData;
  };
  const alteredCofigurableFields = useMemo(
    () => getAlteredConfigurableFields(configurableFields),
    [configurableFields]
  );

  const unFilterableFields = alteredCofigurableFields.filter((field) => !field.isFilterable);
  const unSavedAccessibleFields = alteredCofigurableFields.filter(
    (field) => !internalSavedFields.find((savedField) => savedField.name.includes(field.name))
  );
  const subFields = alteredCofigurableFields.filter((field) => field.isSubField);

  const getTeamOptions = (teams: IOrgTeam[]) => {
    const test = teams.map((team) => ({ text: team.name, value: team.id }));
    return test;
  };

  useEffect(() => {
    if (orgId && !internalSavedFieldsOp) {
      dispatch(getAllFieldData(orgId));
    }
    if (orgId && !teamsOp) {
      dispatch(getOrganizationTeams(orgId));
    }
  }, [dispatch, configurableFieldssOp, internalSavedFieldsOp, orgId, org.org?.domain, teamsOp]);

  const onSaveUnusedFieldsClick = () => {
    const fieldsToSave = unSavedAccessibleFields.map((field) => {
      if (field.type.IsCollection) {
        return {
          name: field.name,
          title: field.name,
          isCustomField: true,
          collectionFormat: '',
          isSensitive: false,
        };
      }
      return {
        name: field.name,
        title: field.name,
        isFilterable: field.isFilterable,
        isSensitive: false,
        isCustomField: true,
      } as InternalSavedField;
    });
    dispatch(saveNewInternalFields({ orgId, fields: fieldsToSave as InternalSavedField[] }));
  };

  const getCollections = () => {
    const collections = alteredCofigurableFields.filter(
      (field) => field.type.IsCollection && field.fields?.length > 0
    );

    return internalSavedFields.filter((field) => collections.some((x) => x.name === field.name));
  };

  const getCollectionKeys = () => {
    const collections = alteredCofigurableFields.filter(
      (field) => field.type.IsCollection && field.fields?.length > 0
    );
    return collections.reduce((acc, cur) => {
      return { ...acc, [cur.name]: cur.fields?.map((x) => x.name) ?? [] };
    }, {} as Record<string, string[]>);
  };

  const getExampleCollection = (
    name: string,
    collectionKeys: Record<string, string[]>,
    noFormat?: boolean
  ) => {
    const keys = collectionKeys[name];
    if (noFormat) return keys.join(', ');
    return keys
      .map((x: string) => {
        return '${' + x + '}';
      })
      .join(' - ');
  };

  const haveUnsavedFields = unSavedAccessibleFields.length > 0;
  const hasSavedFields = internalSavedFields.length > 0;
  const options = getTeamOptions(teams);
  return (
    <ViewFrame
      viewLoader={{
        message: 'Loading internal saved fields...',
        viewOperations,
      }}
    >
      <Box sx={{ height: '65vh' }}>
        {haveUnsavedFields ? (
          <Box sx={{ display: 'flex', gap: '40px', marginBottom: '20px', alignItems: 'center' }}>
            <Typography variant="h5">Unsaved Fields</Typography>
            <Button type="submit" variant="outlined" onClick={() => onSaveUnusedFieldsClick()}>
              Save Unsaved Fields
            </Button>
          </Box>
        ) : null}
        {haveUnsavedFields ? (
          <Box sx={{ margin: '5px 0 10px 10px', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            {unSavedAccessibleFields.map((field) => (
              <Box key={field.name}>{field.name}</Box>
            ))}
          </Box>
        ) : null}
        {hasSavedFields ? (
          <>
            <Typography variant="h5" sx={{ margin: '10px 0' }}>
              Saved Fields
            </Typography>
            <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
              <Button
                variant={editType === EditType.filters ? 'contained' : 'outlined'}
                onClick={() => setEditType(EditType.filters)}
              >
                Edit Title
              </Button>
              <Button
                variant={editType === EditType.format ? 'contained' : 'outlined'}
                onClick={() => setEditType(EditType.format)}
              >
                Edit Formats
              </Button>
              <Button
                variant={editType === EditType.misc ? 'contained' : 'outlined'}
                onClick={() => setEditType(EditType.misc)}
              >
                Edit Misc
              </Button>
              <Button
                variant={editType === EditType.listType ? 'contained' : 'outlined'}
                onClick={() => setEditType(EditType.listType)}
              >
                Edit List Type
              </Button>
              <Button
                variant={editType === EditType.export ? 'contained' : 'outlined'}
                onClick={() => setEditType(EditType.export)}
              >
                Edit Export Format
              </Button>
            </Box>
          </>
        ) : null}
        {hasSavedFields && editType === EditType.filters ? (
          <StandardGrid
            dataSet={internalSavedFields}
            getRowId={(x) => x.id}
            filterPlaceholder="Filter on the saved fields"
            isCellEditable={(x) => {
              if (
                unFilterableFields.some(
                  (field) => field.name.toLowerCase() === x.row.Title.toLowerCase()
                ) &&
                x.colDef.field === SavedFieldEnum.isFilterable
              ) {
                return false;
              }
              if (
                subFields.some(
                  (sub) => sub.name?.toLowerCase() === x.row[SavedFieldEnum.fieldName].toLowerCase()
                ) &&
                x.colDef.field === SavedFieldEnum.isSensitive
              ) {
                return false;
              }
              return true;
            }}
            cols={[
              {
                name: 'Title',
                description: 'Clean name',
                relativeWidth: 1,
                getValue: (x) => x.title ?? 'No Title',
                isEditable: true,
              },
              {
                name: 'Field Name',
                description: 'The actual field name',
                relativeWidth: 2,
                getValue: (x) => x.name,
              },
              {
                name: 'Is Filterable',
                description: 'Can this be used as a filter?',
                getValue: (x) => {
                  if (unFilterableFields.some((field) => field.name === x.name)) {
                    return false;
                  }
                  return !!x.isFilterable;
                },
                relativeWidth: 0.5,
                type: 'boolean',
                isEditable: true,
              },
              {
                name: 'Is Sensitive',
                relativeWidth: 0.5,
                description: 'Hide this for users not on selected teams',
                getValue: (x) => !!x.isSensitive,
                type: 'boolean',
                isEditable: true,
              },
              {
                name: 'Teams',
                relativeWidth: 1,
                getValue: (x) => {
                  const matchedOptions = options.filter((y) => x.teamIds?.includes(y.value));
                  return matchedOptions.map((y) => y.value);
                },
                description: 'Teams that can see this field if sensitive',
                editOptions: options,
                type: 'multiSelect',
                isEditable: true,
              },
            ]}
            getEditAction={(oldRow, newRow) => {
              const getEdit = (x: string) => (oldRow[x] !== newRow[x] ? newRow[x] : undefined);
              const targetField = internalSavedFields.find((field) => {
                return field.id === oldRow.id;
              });

              const newField = { ...targetField } as InternalSavedField;

              const isFilterableChange = getEdit(SavedFieldEnum.isFilterable) as string;
              // do not allow users to edit isFilterable if the index has marked it as unFilterable
              if (
                isFilterableChange !== undefined &&
                !unFilterableFields.some((field) => field.name === newRow[SavedFieldEnum.fieldName])
              ) {
                const newValue = newRow[SavedFieldEnum.isFilterable] as boolean;
                newField.isFilterable = newValue;
              }

              const isSensitiveChange = getEdit(SavedFieldEnum.isSensitive) as string;
              if (isSensitiveChange !== undefined) {
                const newValue = newRow[SavedFieldEnum.isSensitive] as boolean;
                newField.isSensitive = newValue;
              }

              const teamsChange = getEdit(SavedFieldEnum.teams) as string;
              if (teamsChange !== undefined && teamsChange.length > 0) {
                const newValue = newRow[SavedFieldEnum.teams] as string[];
                newField.teamIds = newValue;
                newField.isSensitive = true;
              }
              const titleChange = getEdit(SavedFieldEnum.title) as string;
              if (titleChange !== undefined) {
                const newValue = newRow[SavedFieldEnum.title] as string;
                newField.title = newValue;
              }

              dispatch(updateInternalSavedField({ orgId, field: newField }));
            }}
            getDeleteAction={{
              action: (value) => {
                const field = value as Record<string, string>;
                dispatch(deleteInternalField({ orgId, userId: field.userId, value: field.id }));
              },
              description: 'Delete Field',
              getDeleteInfo: (x) => ({ value: x, confirmName: x.name }),
            }}
          />
        ) : null}
        {hasSavedFields && editType === EditType.format ? (
          <StandardGrid
            dataSet={getCollections()}
            getRowId={(x) => x.id}
            filterPlaceholder="Filter on the saved fields"
            cols={[
              {
                name: 'Field Name',
                description: 'The actual field name',
                relativeWidth: 1,
                getValue: (x) => x.name,
              },
              {
                name: 'Collection Format',
                description: 'How to display the collection',
                relativeWidth: 2,
                getValue: (x) =>
                  x.collectionFormat || `ex: ${getExampleCollection(x.name, getCollectionKeys())}`,
                isEditable: true,
              },
              {
                name: 'Description Format',
                description: 'How to display the description',
                relativeWidth: 2,
                getValue: (x) =>
                  x.descriptionFormat || `ex: ${getExampleCollection(x.name, getCollectionKeys())}`,
                isEditable: true,
              },
              {
                name: 'Fallback display',
                description: 'What we display if the field is empty',
                relativeWidth: 1,
                getValue: (x) => x.fallbackDisplay || 'ex: N/A',
                isEditable: true,
              },
            ]}
            getEditAction={(oldRow, newRow) => {
              const getEdit = (x: string) => (oldRow[x] !== newRow[x] ? newRow[x] : undefined);
              const targetField = internalSavedFields.find((field) => {
                return field.id === oldRow.id;
              });

              const newField = { ...targetField } as InternalSavedField;

              const collectionFormatChange = getEdit(SavedFieldEnum.collectionFormat) as string;
              if (collectionFormatChange !== undefined) {
                const newValue = newRow[SavedFieldEnum.collectionFormat] as string;
                newField.collectionFormat = newValue;
              }

              const descriptionFormatChange = getEdit(SavedFieldEnum.descriptionFormat) as string;
              if (descriptionFormatChange !== undefined) {
                const newValue = newRow[SavedFieldEnum.descriptionFormat] as string;
                newField.descriptionFormat = newValue;
              }

              const fallbackDisplayChange = getEdit(SavedFieldEnum.fallbackDisplay) as string;
              if (fallbackDisplayChange !== undefined) {
                const newValue = newRow[SavedFieldEnum.fallbackDisplay] as string;
                newField.fallbackDisplay = newValue;
              }
              dispatch(updateInternalSavedField({ orgId, field: newField }));
            }}
          />
        ) : null}
        {hasSavedFields && editType === EditType.misc ? (
          <StandardGrid
            dataSet={getCollections()}
            getRowId={(x) => x.id}
            filterPlaceholder="Filter on the saved fields"
            cols={[
              {
                name: 'Field Name',
                description: 'The actual field name',
                relativeWidth: 1,
                getValue: (x) => x.name,
              },
              {
                name: 'Image Url Field',
                description: 'The field in the collection that contains the image url',
                relativeWidth: 2,
                getValue: (x) =>
                  x.imageField || `ex: ${getExampleCollection(x.name, getCollectionKeys())}`,
                isEditable: true,
              },
            ]}
            getEditAction={(oldRow, newRow) => {
              const getEdit = (x: string) => (oldRow[x] !== newRow[x] ? newRow[x] : undefined);
              const targetField = internalSavedFields.find((field) => {
                return field.id === oldRow.id;
              });

              const newField = { ...targetField } as InternalSavedField;

              const imsageUrlFieldChange = getEdit(SavedFieldEnum.imageField) as string;
              if (imsageUrlFieldChange !== undefined) {
                const newValue = newRow[SavedFieldEnum.imageField] as string;
                newField.imageField = newValue;
              }

              dispatch(updateInternalSavedField({ orgId, field: newField }));
            }}
          />
        ) : null}
        {hasSavedFields && editType === EditType.listType ? (
          <StandardGrid
            dataSet={getCollections()}
            getRowId={(x) => x.id}
            filterPlaceholder="Filter on the saved fields"
            cols={[
              {
                name: 'Field Name',
                description: 'The actual field name',
                getValue: (x) => x.name,
              },
              {
                name: 'List Style',
                description: 'How to display the collection',
                getValue: (x) => {
                  const matchedOptions = listStyleTypeOptions.filter((y) =>
                    x.listStyleType?.includes(y.value)
                  );
                  return matchedOptions.map((y) => y.value);
                },
                type: 'multiSelect',
                editOptions: listStyleTypeOptions,
                isEditable: true,
              },
              {
                name: 'Sort Field',
                description: 'Field to sort on',
                getValue: (x) =>
                  x.sortField || `ex: ${getExampleCollection(x.name, getCollectionKeys(), true)}`,
                isEditable: true,
              },
              {
                name: 'Sort Descending',
                description: 'Direction to sort on',
                getValue: (x) => x.sortDescending,
                type: 'boolean',
                isEditable: true,
              },
            ]}
            getEditAction={(oldRow, newRow) => {
              const getEdit = (x: string) => (oldRow[x] !== newRow[x] ? newRow[x] : undefined);
              const targetField = internalSavedFields.find((field) => {
                return field.id === oldRow.id;
              });

              const newField = { ...targetField } as InternalSavedField;

              const listStyleTypeChange = getEdit(SavedFieldEnum.listStyleType) as string;
              if (listStyleTypeChange !== undefined && listStyleTypeChange.length > 0) {
                const newValue = newRow[SavedFieldEnum.listStyleType] as string[];
                newField.listStyleType = newValue[0];
              }

              const sortFieldChange = getEdit(SavedFieldEnum.sortField) as string;
              if (sortFieldChange !== undefined) {
                const newValue = newRow[SavedFieldEnum.sortField] as string;
                newField.sortField = newValue;
              }

              const sortDescending = getEdit(SavedFieldEnum.sortDescending) as boolean;
              if (sortDescending !== undefined) {
                const newValue = newRow[SavedFieldEnum.sortDescending] as boolean;
                newField.sortDescending = newValue;
              }

              dispatch(updateInternalSavedField({ orgId, field: newField }));
            }}
          />
        ) : null}
        {hasSavedFields && editType === EditType.export ? (
          <StandardGrid
            dataSet={getCollections()}
            getRowId={(x) => x.id}
            filterPlaceholder="Filter on the saved fields"
            cols={[
              {
                name: 'Field Name',
                description: 'The actual field name',
                getValue: (x) => x.name,
              },
              {
                name: 'Export Format',
                description: 'How to display the collection',
                getValue: (x) =>
                  x.excelExportFormat || `ex: ${getExampleCollection(x.name, getCollectionKeys())}`,
                isEditable: true,
              },
            ]}
            getEditAction={(oldRow, newRow) => {
              const getEdit = (x: string) => (oldRow[x] !== newRow[x] ? newRow[x] : undefined);
              const targetField = internalSavedFields.find((field) => {
                return field.id === oldRow.id;
              });

              const newField = { ...targetField } as InternalSavedField;

              const exportFormatChange = getEdit(SavedFieldEnum.export) as string;
              if (exportFormatChange !== undefined) {
                const newValue = newRow[SavedFieldEnum.export] as string;
                newField.excelExportFormat = newValue;
              }

              dispatch(updateInternalSavedField({ orgId, field: newField }));
            }}
          />
        ) : null}
      </Box>
    </ViewFrame>
  );
};
