import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import { bulkAssignTeam } from 'api/apiMetaThunks';
import { clearOperations, selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { Selector } from 'components/Selector';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { opAllKeyedSkippedOrSuccess } from 'utils/apiResult';
import { getTeamOptions } from 'utils/selectorOptions';
import { getKeyedOperations, getOperations } from 'utils/operable';
import { uploadCsvFile } from 'utils/files';
import { UploadCsvModal } from 'components/organization/UploadCsvModal';

const Input = styled('input')({ display: 'none' });
const Label = styled('label')({ alignSelf: 'center' });

type Row = Record<string, string>;

/**
 * The organization bulk edit license view
 */
export const EditTeam = (): JSX.Element => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState('');
  const [mode, setMode] = useState('load' as 'load' | 'assign' | 'errorCheck' | 'done');
  const [fromTeamId, setFromTeamId] = useState('');
  const [toTeamId, setToTeamId] = useState('');
  const [processingUserIds, setProcessingUserIds] = useState([] as string[]);
  const [orgUserTeamMap, setOrgUserTeamMap] = useState<Map<string, string | undefined>>();
  const [uploadOperations, setUploadOperations] = useState<Operation[]>([]);
  const [csvHasEmailColumn, setCsvHasEmailColumn] = useState(false);
  const [csvValidEmails, setCsvValidEmails] = useState<string[]>([]);
  const [csvInvalidEmails, setCsvInvalidEmails] = useState<string[]>([]);
  const [csvModalOpen, setCsvModalOpen] = useState<boolean>(false);

  const org = useAppSelector(selectDerivedOrganization);
  const viewOperations = [...getOperations(org, 'users')];
  const contentOperations = [
    ...uploadOperations,
    ...getKeyedOperations(org, processingUserIds, ['assignTeam', 'removeTeam']).flat(),
  ];
  const { orgId, users = [], teams = [] } = org;

  const dispatch = useAppDispatch();

  const complete = useCallback(() => {
    if (orgId) {
      dispatch(clearOperations({ orgId, opNames: ['removeTeam', 'assignTeam'] }));
    }
    setMode('done');
  }, [dispatch, orgId]);

  useEffect(() => {
    if (mode === 'assign') {
      bulkAssignTeam(
        dispatch,
        org,
        processingUserIds,
        toTeamId,
        fromTeamId === 'not_on_a_team',
        () => setMode('errorCheck'),
        orgUserTeamMap
      );
    } else if (mode === 'errorCheck') {
      if (opAllKeyedSkippedOrSuccess(org)) {
        complete();
      }
    }
  }, [dispatch, mode, org, fromTeamId, toTeamId, processingUserIds, orgUserTeamMap, complete]);

  if (!orgId) return <></>;

  const teamOptions = getTeamOptions(teams, true);

  const isPreProcessing = mode === 'load';
  const isProcessing = (!isPreProcessing && mode !== 'done') || !!uploadOperations.length;

  const orgUsers = users.filter((orgUser) => {
    if (!isPreProcessing) return processingUserIds.includes(orgUser.userId);
    if (fromTeamId === 'all') return true;
    if (fromTeamId === 'csv')
      return orgUser.emailAddress && csvValidEmails.includes(orgUser.emailAddress);
    if (fromTeamId === 'not_on_a_team') return !orgUser.assignedTeam?.id;
    return orgUser.assignedTeam?.id === fromTeamId;
  });

  const onUploadOperation = (ops: Operation[]) => {
    // If a single successful operation, then translate to [] (no operation)
    ops = ops.length === 1 && ops[0]?.status === 'succeeded' ? [] : ops;
    setUploadOperations(ops);
  };

  const onUploadSuccess = (newColumns: string[], newRows: Row[]) => {
    const emailColumn = newColumns.find((c) => c.includes('Email'));
    if (!emailColumn) {
      setCsvHasEmailColumn(false);
      setCsvModalOpen(true);
      return;
    }
    const csvEmails = newRows.map((r) => r[emailColumn]?.trim());
    const validEmails = users
      .map((orgUser) => orgUser.emailAddress)
      .filter((emailAddress) => {
        return emailAddress && csvEmails.includes(emailAddress);
      }) as string[];
    const invalidEmails = csvEmails.filter((emailAddress) => !validEmails.includes(emailAddress));
    setCsvHasEmailColumn(true);
    setCsvValidEmails(validEmails);
    setCsvInvalidEmails(invalidEmails);
    if (invalidEmails.length > 0) {
      setCsvModalOpen(true);
    }
    (document.getElementById('contained-button-file') as HTMLInputElement).value = '';
  };

  const enableSubmit = mode === 'load' && fromTeamId && toTeamId;
  const operationDisplayName = 'Assigning';
  const contentMessage = uploadOperations.length ? 'Uploading CSV' : operationDisplayName;

  let message: ReactElement | undefined;

  if (fromTeamId && toTeamId) {
    message = <Typography>{`This will apply to ${orgUsers.length} users`}</Typography>;
  }

  return (
    <ViewFrame
      viewLoader={{
        message: 'Loading users and teams for the organization. This might take a minute.',
        viewOperations,
      }}
      header={
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Selector
              label="Find users with this team"
              options={[{ value: 'csv', text: 'From CSV' }].concat(teamOptions)}
              value={fromTeamId}
              setValue={(val) => {
                setFromTeamId(val as string);
                setMode('load');
              }}
              isDisabled={isProcessing}
              sx={{ minWidth: 300 }}
            />
            <Selector
              label="Migrate them to this team"
              options={teamOptions.filter((x) => x.value !== 'all')}
              value={toTeamId}
              setValue={(val) => {
                setToTeamId(val as string);
                setMode('load');
              }}
              isDisabled={isProcessing}
              sx={{ minWidth: 300 }}
            />
          </Box>

          {fromTeamId === 'csv' && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
              <Label htmlFor="contained-button-file">
                <Input
                  accept="text/csv"
                  id="contained-button-file"
                  type="file"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    uploadCsvFile(e, onUploadOperation, onUploadSuccess)
                  }
                />
                <Button variant="contained" component="span" sx={{ minWidth: 125 }}>
                  Upload CSV
                </Button>
              </Label>
            </Box>
          )}

          <Button
            variant="contained"
            disabled={!enableSubmit}
            onClick={() => {
              setConfirmModalTitle(`Edit team for ${orgUsers.length} users?`);
              setConfirmModalOpen(true);
            }}
          >
            Submit
          </Button>

          {message}
        </>
      }
      contentLoader={{
        message: contentMessage,
        contentOperations,
        forceClose: !isProcessing,
        onClose: () => {
          complete();
          setUploadOperations([]);
          setCsvValidEmails([]);
          setCsvInvalidEmails([]);
        },
      }}
    >
      <StandardGrid
        dataSet={orgUsers}
        tipModel="OrgUser"
        getRowId={(x) => x.userId}
        getOpenAction={(x) =>
          x.newUserId ? { email: x.emailAddress, type: 'NewUser' } : { id: x.userId, type: 'User' }
        }
        filterPlaceholder="Filter the displayed users. (Warning: filtered-out users will still be transfered)"
        cols={[
          {
            name: 'Name',
            valueProperty: 'fullName',
          },
          {
            name: 'Email',
            valueProperty: 'emailAddress',
            type: 'email',
          },
          {
            name: 'Team',
            valueProperty: 'assignedTeam',
            getValue: (x) => x.assignedTeam?.name,
          },
        ]}
      />

      <ConfirmModal
        open={confirmModalOpen}
        prompt={confirmModalTitle}
        onClose={() => setConfirmModalOpen(false)}
        onAccept={() => {
          setOrgUserTeamMap(
            new Map(orgUsers.map((orgUser) => [orgUser.userId, orgUser.assignedTeam?.id]))
          );
          setProcessingUserIds(orgUsers.map((orgUser) => orgUser.userId));
          setMode('assign');
        }}
      />

      <UploadCsvModal
        onClose={() => setCsvModalOpen(false)}
        open={csvModalOpen}
        csvHasEmailColumn={csvHasEmailColumn}
        csvInvalidEmails={csvInvalidEmails}
      />
    </ViewFrame>
  );
};
