import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import PreviewIcon from '@mui/icons-material/PreviewOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import CancelIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  gridStringOrNumberComparator,
  useGridApiContext,
} from '@mui/x-data-grid';
import compact from 'lodash/compact';
import fromPairs from 'lodash/fromPairs';
import Papa from 'papaparse';
import { useState } from 'react';

import { useAppDispatch } from 'app/hooks';
import { ColumnModal } from 'components/modals/ColumnModal';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { OpenLookupButton } from 'components/OpenLookupButton';
import { SearchBar } from 'components/SearchBar';
import { Tip } from 'components/Tip';
import { IS_UNIT_TEST } from 'constant';
import { addLookupTab } from 'features/frame/appViewSlice';
import { asCleanEmail, asDateISOString, asDisplayString } from 'utils/convert';
import { downloadFile } from 'utils/files';
import { Box, Divider, SxProps, Theme } from '@mui/material';

interface GridCol<T> {
  /** The column name, displayed to the user and used internally as a key */
  name: string;
  /** The column description for the tooltip. May be overwritten by a TooltipDefinition from valueProperty */
  description?: string;
  /** The relative width of this column. Default 1. */
  relativeWidth?: number;
  /** Additional styles of this column. */
  sx?: SxProps<Theme>;
  /** A key that indexes into the data object. Used to get the TooltipDefinition. If getValue is not provided will also be used to get the value. */
  valueProperty?: Extract<keyof T, string>;
  /** A function to generate the value from the data object. MUST be provided if valueProperty is undefined. */
  getValue?: (base: T) => unknown;
  /** A function to generate Tip model/property values */
  getTipData?: (base: T) => {
    model: string;
    property: string;
  };
  /** A function to generate a value tooltip from the data object */
  getTooltip?: (base: T) => unknown;
  /** The type of values in the column. Controls display and editing style. Default 'string' */
  type?: 'string' | 'number' | 'date' | 'boolean' | 'blob' | 'email' | 'multiSelect';
  /** If true, this column will be hidden from view */
  hide?: boolean;
  /** The options to show in edit mode. */
  editOptions?: SelectOption[];
  /** True to allow edits to the values in this column */
  isEditable?: boolean;
  /** For type='blob', the Tip model to use on the modal popup */
  innerModel?: string;
  /** True to forcefully disable edits to the values in this column */
  disabled?: boolean;
}

interface ToolbarProps<T> {
  onChange: (value: string) => void;
  placeholder: string;
  cols: GridCol<T>[];
  rows: Record<string, unknown>[];
  showSearchBar: boolean;
}

function valueFormatter<T>(column: GridCol<T>, value: unknown) {
  const editOptions = column?.editOptions;
  if (!editOptions) return value;

  const cast = (val: unknown) =>
    typeof val === 'string' ? editOptions.find((x) => x.value === val)?.text ?? val : val;

  if (Array.isArray(value)) {
    return value.map(cast).sort();
  }

  return cast(value);
}

function Toolbar<T>({
  onChange,
  placeholder,
  cols,
  rows,
  showSearchBar = true,
}: ToolbarProps<T>): JSX.Element {
  const columnNames = cols.map((col) => col.name);
  const onDownload = () => {
    // Change grid cell content before download
    rows = rows.map((row) => {
      const newRow: Record<string, unknown> = {};
      return Object.keys(row).reduce((prev, cur, idx) => {
        if (Array.isArray(row[cur])) {
          prev[cur] = JSON.stringify(valueFormatter(cols[idx], row[cur]));
        } else if (row[cur] !== null && typeof row[cur] === 'object') {
          prev[cur] = JSON.stringify(row[cur]);
        } else {
          prev[cur] = valueFormatter(cols[idx], row[cur]);
        }

        // Replace grid cell content with ISO string if it's a date or datetime
        if (cols[idx]?.type === 'date') {
          prev[cur] = asDateISOString(prev[cur]);
        }

        return prev;
      }, newRow);
    });

    const csvContent = Papa.unparse({ fields: columnNames, data: rows });
    downloadFile('seekout-admin-data', csvContent, 'text/csv');
  };

  return (
    <span style={{ display: 'flex' }}>
      {showSearchBar && (
        <>
          <SearchBar onChange={onChange} placeholder={placeholder} />
          <IconButton onClick={onDownload} disabled={rows.length === 0}>
            <FileDownloadIcon />
          </IconButton>
        </>
      )}
    </span>
  );
}

function MultiSelect({ id, value = [], field, colDef, row }: GridRenderCellParams<string[]>) {
  const apiRef = useGridApiContext();
  const options = Array.isArray(colDef.valueOptions) ? colDef.valueOptions : [];

  return (
    <Select
      multiple
      value={value}
      onChange={(e) => {
        apiRef.current.setEditCellValue({ id, field, value: e.target.value });
      }}
      sx={{ width: '100%' }}
    >
      {options.map((option) => {
        const opt = typeof option === 'object' ? option : { value: option, label: option };
        if (opt.value === 'Divider') return <Divider />;
        const isDisabled =
          Array.isArray(row.disabledOptionIds) && row.disabledOptionIds.includes(opt.value)
            ? true
            : false;
        return (
          <MenuItem
            value={opt.value}
            key={opt.value}
            sx={isDisabled ? { backgroundColor: '#D3D3D3 !important', color: 'black' } : {}}
            disabled={isDisabled}
          >
            {opt.label}
          </MenuItem>
        );
      })}
    </Select>
  );
}

interface Props<T> {
  cols: GridCol<T>[];
  dataSet: T[];
  getRowId: (data: T) => string;
  getOpenAction?: (data: T) => Lookup;
  getDeleteAction?: {
    action: (value: unknown) => void;
    description: string;
    getDeleteInfo: (data: T) => {
      value: unknown;
      confirmName: string;
    };
  };
  getEditAction?: (oldRow: Record<string, unknown>, newRow: Record<string, unknown>) => void;
  getCustomEditAction?: {
    action: (value: T) => void;
  };
  getCustomActions?: {
    getId: (value: T) => void;
    actionItems: (value: T) => ActionItemProps<T>[];
    action: (label: string, value: T) => void;
  };
  filterPlaceholder?: string;
  tipModel?: string;
  isCellEditable?: (params: GridCellParams) => boolean;
  disabledOptionIds?: (row: T) => string[];
  showSearchBar?: boolean;
  onRowClick?: (row: T) => void;
}

interface DetailModalInfo {
  title?: string;
  data?: Record<string, unknown>;
  model?: string;
}

/**
 * A filterable, standardized DataGrid
 */
export function StandardGrid<T>({
  cols,
  dataSet,
  getRowId,
  getOpenAction,
  getDeleteAction,
  getEditAction,
  getCustomEditAction,
  getCustomActions,
  filterPlaceholder,
  tipModel,
  isCellEditable,
  disabledOptionIds,
  showSearchBar,
  onRowClick,
}: Props<T>): JSX.Element {
  const [detailModal, setDetailModal] = useState<DetailModalInfo>({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalPrompt, setConfirmModalPrompt] = useState('');
  const [confirmModalAccept, setConfirmModalAccept] = useState<() => void>(() => () => {
    /* Nothing */
  });

  const [filterText, setFilterText] = useState('');

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const stripped = filterText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  const searchRegex = new RegExp(stripped, 'i');

  const setRowMode = (id: GridRowId, type: 'edit' | 'view', ignoreModifications?: boolean) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: type === 'edit' ? GridRowModes.Edit : GridRowModes.View, ignoreModifications },
    });
  };

  const dispatch = useAppDispatch();

  // Create Rows
  const rows = compact(
    dataSet.map((data) => {
      // Create a row with all the transformed values
      const row = fromPairs(
        cols.map((col) => {
          const value = col.getValue
            ? col.getValue(data)
            : col.valueProperty
            ? data[col.valueProperty]
            : '';
          return [col.name, col.type === 'email' ? asCleanEmail(`${value}`) : value];
        })
      );

      // If nothing in the row matches the filter, omit this row
      if (
        !Object.values(row).some((value) =>
          searchRegex.test(typeof value === 'object' ? JSON.stringify(value) : `${value}`)
        )
      ) {
        return;
      }

      // Add tooltip and id information to the row
      row.tooltips = Object.fromEntries(
        cols.map((col) => [
          col.name,
          {
            text: col.getTooltip ? col.getTooltip(data) : row[col.name],
            data: col.getTipData ? col.getTipData(data) : undefined,
          },
        ])
      );

      row.displayStrings = fromPairs(
        cols.map((col) => [col.name, asDisplayString(valueFormatter(col, row[col.name]))])
      );

      row.id = getRowId(data);
      row.disabledOptionIds = disabledOptionIds?.(data) ?? [];

      if (getOpenAction) {
        row.openActionLookup = getOpenAction(data);
      }

      if (getDeleteAction) {
        const { value, confirmName } = getDeleteAction.getDeleteInfo(data);
        row.deleteValue = value;
        row.deleteConfirmValue = confirmName;
      }

      if (getCustomEditAction) {
        row.editValue = data;
      }

      if (getCustomActions) {
        row.value = data;
      }
      return row;
    })
  );

  // Create Column Definitions
  let columns: GridColumns = [];

  if (getOpenAction) {
    columns.push({
      field: 'openActionLookup',
      type: 'actions',
      flex: 0.5,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <OpenLookupButton
                openLookup={() => dispatch(addLookupTab(params.row.openActionLookup))}
              />
            }
            label={'open lookup tab'}
          />,
        ];
      },
      renderHeader: () => (
        <Tip title="Open lookup tab">
          <Typography variant="subtitle2">Lookup</Typography>
        </Tip>
      ),
    });
  }

  columns = columns.concat(
    cols.map((col) => {
      const colDef: GridColDef = {
        field: col.name,
        headerName: col.name,
        description: col.description,
        flex: col.relativeWidth || 1,
        type: col.type || 'string',
        hide: col.hide,
        editable: !col.disabled && (col.isEditable || !!col.editOptions?.length),
        renderHeader: (params) => (
          <Tip title={params.colDef.description} model={tipModel} property={col.valueProperty}>
            <Typography variant="subtitle2">{params.field}</Typography>
          </Tip>
        ),
      };

      if (col.type === 'email') {
        colDef.type = 'string';
      }

      // Tooltips and cell renderers
      if (col.type === 'blob') {
        colDef.flex = col.relativeWidth || 3;
        colDef.type = 'string';
        colDef.sortComparator = (v1, v2, p1, p2) => {
          if (!v1) return -1;
          if (!v2) return 1;

          const keyLen = Object.keys(v1).length - Object.keys(v2).length;
          return keyLen !== 0
            ? keyLen
            : gridStringOrNumberComparator(JSON.stringify(v1), JSON.stringify(v2), p1, p2);
        };
        colDef.renderCell = (params) => {
          // Turn this object into a string we can display in our table
          const displayString = params.row.displayStrings[col.name];
          return (
            <Tip title={params.value}>
              <Box sx={col.sx}>
                {displayString.length > 80 && (
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setDetailModal({
                        title: `${params.colDef.headerName} Details`,
                        data: params.value,
                        model: col.innerModel,
                      });
                    }}
                  >
                    <PreviewIcon />
                  </IconButton>
                )}
                {displayString}
              </Box>
            </Tip>
          );
        };
      } else if (col.type !== 'boolean' && col.type !== 'number') {
        colDef.renderCell = (params) => {
          const { text, data } = params.row.tooltips[col.name];
          return (
            <Tip title={text} model={data?.model} property={data?.property}>
              <Box sx={col.sx}>{params.row.displayStrings[col.name]}</Box>
            </Tip>
          );
        };
      }

      if (col.editOptions?.length) {
        if (col.type === 'multiSelect') {
          colDef.type = 'string';
          colDef.valueFormatter = ({ value }) => {
            return valueFormatter(col, value);
          };
          colDef.valueOptions = col.editOptions.map(({ value, text }) => ({ value, label: text }));
          colDef.renderEditCell = (params) => <MultiSelect {...params} />;
        } else if (!col.type || col.type === 'string') {
          colDef.type = 'singleSelect';
          colDef.valueFormatter = ({ value }) => {
            return valueFormatter(col, value);
          };
          colDef.valueOptions = col.editOptions.map(({ value, text }) => ({ value, label: text }));
        }
      }

      return colDef;
    })
  );

  if (getDeleteAction || getEditAction || getCustomEditAction || getCustomActions) {
    let headerName = 'Actions';
    let description = 'Actions';

    if (getDeleteAction && !getEditAction && !getCustomEditAction && !getCustomActions) {
      headerName = 'Delete';
      description = getDeleteAction.description;
    } else if ((getEditAction || getCustomEditAction) && !getDeleteAction && !getCustomActions) {
      headerName = 'Edit';
    }

    columns = [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        flex: 0.8,
        getActions: ({ id, row }) => {
          const isEditMode = getEditAction && rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                color="primary"
                onClick={() => setRowMode(id, 'view')}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                color="warning"
                onClick={() => setRowMode(id, 'view', true)}
              />,
            ];
          }

          const cells: JSX.Element[] = [];

          if (getEditAction) {
            cells.push(
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                color="primary"
                onClick={() => setRowMode(id, 'edit')}
              />
            );
          }

          if (getCustomEditAction) {
            const actionFunc = () => getCustomEditAction.action(row.editValue);

            cells.push(
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                color="primary"
                onClick={actionFunc}
              />
            );
          }

          if (getDeleteAction) {
            const confirmValue = row.deleteConfirmValue;
            const actionFunc = () => getDeleteAction.action(row.deleteValue);

            cells.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                color="error"
                onClick={() => {
                  setConfirmModalOpen(true);
                  setConfirmModalPrompt(`${getDeleteAction.description}: ${confirmValue}?`);
                  // Need to wrap actionFunc in additional function for React Hooks reasons
                  setConfirmModalAccept(() => actionFunc);
                }}
              />
            );
          }

          if (getCustomActions) {
            const rowId = getCustomActions.getId(row.value);
            const cellItems = getCustomActions
              .actionItems(row.value)
              .map((actionItem, index) => (
                <GridActionsCellItem
                  key={`${rowId}actionItem${index}`}
                  icon={actionItem.icon}
                  label={actionItem.label}
                  color={actionItem.color}
                  onClick={() => getCustomActions.action(actionItem.label, row.value)}
                  disabled={actionItem.disableItem?.(row.value)}
                />
              ));
            cells.push(...cellItems);
          }

          return cells;
        },
        renderHeader: () => (
          <Tip title={description}>
            <Typography variant="subtitle2">{headerName}</Typography>
          </Tip>
        ),
      },
    ];
  }

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight={IS_UNIT_TEST}
        autoPageSize={!IS_UNIT_TEST}
        density="compact"
        isCellEditable={(x: GridCellParams) => {
          if (isCellEditable) {
            return isCellEditable(x);
          }
          return true;
        }}
        components={{
          Toolbar: Toolbar,
        }}
        componentsProps={{
          toolbar: {
            onChange: (value: string) => setFilterText(value),
            placeholder: filterPlaceholder || 'Filter...',
            cols: cols,
            rows: rows,
            showSearchBar: showSearchBar,
          },
        }}
        onRowClick={(params) => {
          if (onRowClick) {
            onRowClick(params.row);
          }
        }}
        disableColumnMenu
        columnBuffer={IS_UNIT_TEST ? columns.length : 0}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowEditStart={(_, event) => {
          event.defaultMuiPrevented = true;
        }}
        onRowEditStop={(_, event) => {
          event.defaultMuiPrevented = true;
        }}
        processRowUpdate={(newRow, oldRow) => {
          if (getEditAction) {
            getEditAction(oldRow, newRow);
          }
          // Always return old row, the data will update when the prop rows change
          return oldRow;
        }}
        // Not really experimental, MUI says it's stable
        // But MUI wants it to sit a major verison before introducing a breaking change
        experimentalFeatures={{ newEditingApi: true }}
      />

      <ColumnModal
        open={!!detailModal.title}
        title={detailModal.title ?? ''}
        data={detailModal.data ?? {}}
        model={detailModal.model}
        onClose={() => setDetailModal({})}
      />

      <ConfirmModal
        open={confirmModalOpen}
        prompt={confirmModalPrompt}
        onClose={() => setConfirmModalOpen(false)}
        onAccept={confirmModalAccept}
      />
    </>
  );
}
