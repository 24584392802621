import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getOperations } from 'utils/operable';
import {
  getAtsConnections,
  getExtractionStatus,
  getTransformationDriverProcessFlag,
  setupInitializeV2Connections,
} from 'api/apiThunks';

import { createFailedOperation } from 'utils/apiResult';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { CommonViewFrame } from 'components/integrations/CommonViewFrame';

type IntegrationOpName = 'initializeConnections' | undefined;
/**
 * The Integrations view
 */
export const Integrations = (): JSX.Element => {
  const org = useAppSelector(selectDerivedOrganization);
  const viewOperations = getOperations(org, 'atsConnections');
  const [atsConnectionsOp] = viewOperations;
  const { atsConnections = [] } = org;
  const displayTabs = ['Scheduler', 'Extraction', 'Driver', 'Auditor', 'Distribution', 'Reporting'];
  const orgId = org?.orgId;
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedConnectionId, setSelectedConnectionId] = useState<string | null>(null);
  const [operations, setOperations] = useState<Operation[]>([]);
  const [extractionStatus, setExtractionStatus] = useState<IExtractionStatus | null>(null);
  const [transformationDriverProcessFlag, setTransformationDriverProcessFlag] =
    useState<boolean>(false);
  const [action, setAction] = useState<string | undefined>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const adminPrivilege = usePrivilegeSelector();
  const [operationType, setOperationType] = useState<IntegrationOpName>();
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  useEffect(() => {
    if (!atsConnectionsOp) {
      dispatch(getAtsConnections(orgId));
    }
  }, [dispatch, atsConnectionsOp, orgId]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredConnections = () => {
    if (!searchTerm) return atsConnections;

    const lowerSearchTerm = searchTerm.toLowerCase();

    const filtered = atsConnections.filter((connection) => {
      const orgDomain = (connection.orgDomain || '').toLowerCase();
      const atsType = connection.atsType.toLowerCase();
      const connectionId = connection.id.toLowerCase();

      return (
        orgDomain.includes(lowerSearchTerm) ||
        atsType.includes(lowerSearchTerm) ||
        connectionId.includes(lowerSearchTerm)
      );
    });

    return filtered.sort((a, b) => {
      const fieldsA = {
        orgDomain: (a.orgDomain || '').toLowerCase(),
        atsType: a.atsType.toLowerCase(),
        connectionId: a.id.toLowerCase(),
      };
      const fieldsB = {
        orgDomain: (b.orgDomain || '').toLowerCase(),
        atsType: b.atsType.toLowerCase(),
        connectionId: b.id.toLowerCase(),
      };

      const hasExactMatchA = Object.values(fieldsA).some((value) => value === lowerSearchTerm);
      const hasExactMatchB = Object.values(fieldsB).some((value) => value === lowerSearchTerm);
      if (hasExactMatchA && !hasExactMatchB) return -1;
      if (hasExactMatchB && !hasExactMatchA) return 1;

      const hasStartsWithA = Object.values(fieldsA).some((value) =>
        value.startsWith(lowerSearchTerm)
      );
      const hasStartsWithB = Object.values(fieldsB).some((value) =>
        value.startsWith(lowerSearchTerm)
      );
      if (hasStartsWithA && !hasStartsWithB) return -1;
      if (hasStartsWithB && !hasStartsWithA) return 1;

      return a.id.localeCompare(b.id);
    });
  };

  let contentMessage;
  switch (operationType) {
    case 'initializeConnections':
      contentMessage = 'Intializing V2 Connections';
      break;
    default:
      contentMessage = '';
      break;
  }

  const fetchExtractionStatus = async (connectionId: string): Promise<void> => {
    fetchTransformationDriverProcessFlag(connectionId);
    try {
      const response = await getExtractionStatus(connectionId);
      if (response) {
        setAction(undefined);
        setOperations([]);
        setExtractionStatus(response);
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Get Extraction Status failed: ${message}`)]);
    }
  };

  const initializeV2Connections = async (connId: string): Promise<void> => {
    setOperationType('initializeConnections');
    setAction('');
    setOperations([{ status: 'loading' }]);
    try {
      await setupInitializeV2Connections(connId);
      setAction(undefined);
      setOperations([]);
      setPopupMessage('V2 Connections successfully initialized!');
      setPopupOpen(true);
    } catch (error) {
      setAction(undefined);
      setOperations([]);
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Initialize V2 Connections failed: ${message}`)]);
    }
  };

  const fetchTransformationDriverProcessFlag = async (connectionId: string): Promise<void> => {
    try {
      const response = await getTransformationDriverProcessFlag(connectionId);
      setTransformationDriverProcessFlag(response);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Get Transformation Driver Process flag: ${message}`)]);
    }
  };

  const handleRowClick = (rowId: string) => {
    const connectionId = rowId?.split('*')[0];
    setSelectedConnectionId(connectionId);
    fetchExtractionStatus(connectionId);
  };

  const isProcessing = !!action || !!operations.length;

  return (
    <CommonViewFrame
      operations={operations}
      isProcessing={isProcessing}
      contentMessage={contentMessage}
      setAction={setAction}
      setOperations={setOperations}
      adminPrivilege={adminPrivilege}
      searchTerm={searchTerm}
      handleSearchChange={handleSearchChange}
      filteredConnections={filteredConnections}
      handleRowClick={handleRowClick}
      selectedConnectionId={selectedConnectionId}
      extractionStatus={extractionStatus}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      transformationDriverProcessFlag={transformationDriverProcessFlag}
      displayTabs={displayTabs}
      popupOpen={popupOpen}
      setPopupOpen={setPopupOpen}
      popupMessage={popupMessage}
      initializeV2Connections={initializeV2Connections}
    />
  );
};
