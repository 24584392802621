import { useState, useEffect } from 'react';
import { Modal } from 'components/modals/Modal';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { Box, TextField } from '@mui/material';
import { ViewFrame } from 'features/frame/ViewFrame';
import { Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import {
  terminateExtraction,
  startExtraction,
  saveConfiguration,
  getExtractionProperties,
  getExtractionConfiguration,
} from 'api/apiThunks';
import { createFailedOperation } from 'utils/apiResult';

interface Props {
  connectionId?: string;
}

type ExtractionOpName =
  | 'saveConfiguration'
  | 'terminateExtraction'
  | 'startExtraction'
  | 'getExtractionProperties'
  | 'getExtractionConfiguration'
  | undefined;

/**
 * The Extraction view
 */
export const Extraction = ({ connectionId }: Props): JSX.Element => {
  const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
  const [saveConfigStatusMessage, setSaveConfigStatusMessage] = useState('');
  const [confirmTerminateModalOpen, setConfirmTerminateModalOpen] = useState(false);
  const [handleStartExtraction, setHandleStartExtraction] = useState(false);
  const [action, setAction] = useState<string | undefined>();
  const [operationType, setOperationType] = useState<ExtractionOpName>();
  const [operations, setOperations] = useState<Operation[]>([]);
  const [jsonResult, setJsonResult] = useState<Record<string, unknown>[] | string | null>(null);
  const [processesJsonResult, setProcessesJsonResult] = useState('');

  let contentMessage;
  switch (operationType) {
    case 'terminateExtraction':
      contentMessage = 'Terminating extraction';
      break;
    case 'startExtraction':
      contentMessage = 'Starting extraction';
      break;
    case 'saveConfiguration':
      contentMessage = 'Saving configuration';
      break;
    case 'getExtractionProperties':
      contentMessage = 'Fetching extraction properties';
      break;
    case 'getExtractionConfiguration':
      contentMessage = 'Fetching extraction configuration';
      break;
    default:
      contentMessage = '';
      break;
  }

  useEffect(() => {
    if (connectionId) {
      getExtractionScheduleJson(connectionId);
    }
  }, [connectionId]);

  const openCreateOrUpdateModal = async () => {
    if (connectionId) {
      setOperationType('getExtractionConfiguration');
      setAction('fetch');
      setOperations([{ status: 'loading' }]);
      try {
        const response = await getExtractionConfiguration(connectionId);
        if (response && response.processes) {
          setAction(undefined);
          setOperations([]);
          setProcessesJsonResult(JSON.stringify(response.processes, null, 2));
          setCreateOrUpdateModalOpen(true);
        }
      } catch (error) {
        setJsonResult('');
        const message = error instanceof Error ? error.message : `${error}`;
        setOperations([
          createFailedOperation(
            `Error in fetching Extraction Configuration for ${connectionId}: ${message}`
          ),
        ]);
      } finally {
        setAction(undefined);
        setOperations([]);
      }
    }
  };

  const getExtractionScheduleJson = async (connectionId: string): Promise<void> => {
    setOperationType('getExtractionProperties');
    setAction('fetch');
    setOperations([{ status: 'loading' }]);
    try {
      const response = await getExtractionProperties(connectionId);
      if (response) {
        setAction(undefined);
        setOperations([]);
        setJsonResult(response);
      }
    } catch (error) {
      setJsonResult('');
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([
        createFailedOperation(
          `Error in fetching Extraction Properties for ${connectionId}: ${message}`
        ),
      ]);
    }
  };

  const terminateExtractionJob = async () => {
    setConfirmTerminateModalOpen(true);
  };

  const handleTerminate = async () => {
    setOperationType('terminateExtraction');
    setAction('');
    setOperations([{ status: 'loading' }]);
    try {
      if (connectionId) {
        await terminateExtraction(connectionId);
      }
      setAction(undefined);
      setOperations([]);
    } catch (error) {
      setJsonResult('');
      const message = error instanceof Error ? error.message : `${error}`;
      setAction(undefined);
      setOperations([createFailedOperation(`Error termination extraction: ${message}`)]);
    }
  };

  const handleStart = async () => {
    setOperationType('startExtraction');
    setAction('');
    setOperations([{ status: 'loading' }]);
    try {
      if (connectionId) {
        await startExtraction(connectionId);
      }
      setAction(undefined);
      setOperations([]);
      setHandleStartExtraction(true);
    } catch (error) {
      setJsonResult('');
      const message = error instanceof Error ? error.message : `${error}`;
      setAction(undefined);
      setOperations([createFailedOperation(`Error in setting circuit break flag: ${message}`)]);
    }
  };

  const handleCreateUpdate = async () => {
    const msg = processesJsonResult
      ? 'Configuration saved/updated successfully!'
      : 'Please enter data';
    if (processesJsonResult) {
      setOperationType('saveConfiguration');
      setAction('');
      setOperations([{ status: 'loading' }]);
      try {
        setCreateOrUpdateModalOpen(false);
        if (connectionId) {
          await saveConfiguration(connectionId, JSON.parse(processesJsonResult));
        }
        setAction(undefined);
        setOperations([]);
        setSaveConfigStatusMessage(msg);
      } catch (error) {
        setJsonResult('');
        const message = error instanceof Error ? error.message : `${error}`;
        setAction(undefined);
        setOperations([createFailedOperation(`Error saving configuring: ${message}`)]);
      }
    }
  };

  const isProcessing = !!action || !!operations.length;

  return (
    <ViewFrame
      contentLoader={{
        message: contentMessage || '',
        contentOperations: operations,
        forceClose: !isProcessing,
        onClose: () => {
          setAction('');
          setOperations([]);
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
        <Box sx={{ ml: 2, mr: 2 }}>
          {handleStartExtraction ? (
            <Alert
              severity="success"
              variant="outlined"
              color="warning"
              onClose={() => setHandleStartExtraction(false)}
            >
              Extraction Started!
            </Alert>
          ) : null}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2, mt: 2 }}>
          <Box sx={{ display: 'flex', width: '75%', marginBottom: 2 }}>
            {!jsonResult ? (
              <Typography
                variant="body2"
                sx={{
                  color: '#555',
                  height: '50vh',
                  overflow: 'auto',
                  border: '1px solid #ccc',
                  width: '100%',
                }}
              >
                {'No Rows'}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  maxHeight: '50vh',
                  overflow: 'auto',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'monospace',
                  textAlign: 'left',
                  border: '1px solid #ccc',
                  width: '100%',
                }}
              >
                {JSON.stringify(jsonResult, null, 2)}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 1 }}>
            <Button variant="outlined" onClick={() => openCreateOrUpdateModal()}>
              Create Or Update
            </Button>
            <Button
              variant="outlined"
              sx={{ marginTop: 1 }}
              onClick={() => terminateExtractionJob()}
            >
              Terminate
            </Button>
            <Button variant="outlined" onClick={() => handleStart()} sx={{ marginTop: 1 }}>
              Start
            </Button>
          </Box>
        </Box>
      </Box>
      <ConfirmModal
        open={confirmTerminateModalOpen}
        prompt={`Are you sure you want to terminate the extraction?`}
        onClose={() => setConfirmTerminateModalOpen(false)}
        onAccept={() => {
          handleTerminate();
        }}
      />

      <Modal
        title="Create/Update Configuration"
        open={createOrUpdateModalOpen}
        onClose={() => setCreateOrUpdateModalOpen(false)}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'sm',
          PaperProps: { sx: { display: 'flex', flexDirection: 'column', height: '100%' } },
        }}
        actions={
          <>
            <Button variant="contained" onClick={handleCreateUpdate}>
              Save
            </Button>
            <Button onClick={() => setCreateOrUpdateModalOpen(false)}>Close</Button>
          </>
        }
        buttonText="Close"
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 5 }}>
          <Box sx={{ display: 'flex', width: '75%', marginBottom: 2 }}>
            <TextField
              sx={{ width: '100%' }}
              variant="outlined"
              value={processesJsonResult}
              onChange={(e) => setProcessesJsonResult(e.target.value)}
              disabled={false}
              multiline={true}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Typography>{saveConfigStatusMessage}</Typography>
        </Box>
      </Modal>
    </ViewFrame>
  );
};
