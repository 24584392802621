import React, { useState } from 'react';
import { ViewFrame } from 'features/frame/ViewFrame';
import { CommonEntityViewer } from 'components/integrations/CommonEntityViewer';
import { handleFetchEntityDetails } from 'api/apiThunks';
import { createFailedOperation } from 'utils/apiResult';

interface Props {
  connectionId?: string;
}

export const Auditor = ({ connectionId }: Props): JSX.Element => {
  const [jsonData, setJsonData] = useState<Record<string, unknown>[] | string | null>(null);
  const [entityId, setEntityId] = useState<string>('');
  const [entityType, setEntityType] = useState<string>('');
  const [operations, setOperations] = useState<Operation[]>([]);
  const [action, setAction] = useState<string | undefined>();

  const fetchAuditorJSON = async (
    connectionId: string,
    entityId: string,
    entityType: string
  ): Promise<void> => {
    setAction('fetch');
    setOperations([{ status: 'loading' }]);

    try {
      const response = await handleFetchEntityDetails(connectionId, entityId, entityType);
      setJsonData(response);
      setOperations([]);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Error fetching Auditor Entity Details: ${message}`)]);
      setJsonData(null);
    } finally {
      setAction(undefined);
    }
  };

  return (
    <ViewFrame
      contentLoader={{
        message: action ? 'Fetching Auditor JSON' : '',
        contentOperations: operations,
        forceClose: !action && operations.length === 0,
        onClose: () => {
          setAction('');
          setOperations([]);
        },
      }}
    >
      <CommonEntityViewer
        connectionId={connectionId}
        entityId={entityId}
        setEntityId={setEntityId}
        entityType={entityType}
        setEntityType={setEntityType}
        jsonData={jsonData}
        onFetchEntity={fetchAuditorJSON}
        loadingMessage="Auditor JSON "
      />
    </ViewFrame>
  );
};
