import { LoginHistory as NewUserLoginHistory } from 'features/newUser/LoginHistory';
import { Settings as NewUserSettings } from 'features/newUser/Settings';
import { SSOHistory as NewUserSSOHistory } from 'features/newUser/SSOHistory';
import { Summary as NewUserSummary } from 'features/newUser/Summary';
import { AtsConnections as OrgAtsConnections } from 'features/organization/AtsConnections';
import { AtsReport as OrgAtsReport } from 'features/organization/AtsReport';
import { RoiReport as OrgRoiReport } from 'features/organization/RoiReport';
import { AtsRediscoveryLogs as OrgAtsRediscoveryLogs } from 'features/organization/AtsRediscoveryLogs';
import { AtsExportHistory as OrgAtsExportHistory } from 'features/organization/AtsExportHistory';
import { CandidateStatuses as OrgCandidateStatuses } from 'features/organization/CandidateStatuses';
import { CreateUsers as OrgCreateUsers } from 'features/organization/CreateUsers';
import { DeleteUsers as OrgDeleteUsers } from 'features/organization/DeleteUsers';
import { EditLicense as OrgEditLicense } from 'features/organization/EditLicense';
import { EditTeam as OrgEditTeam } from 'features/organization/EditTeam';
import { ExportFormats as OrgExportFormats } from 'features/organization/ExportFormats';
import { Monitor as OrgMonitor } from 'features/organization/Monitor';
import { PowerFilters as OrgPowerFilters } from 'features/organization/PowerFilters';
import { SearchHistory as OrgSearchHistory } from 'features/organization/SearchHistory';
import { Settings as OrgSettings } from 'features/organization/Settings';
import { Edit as OrgEdit } from 'features/organization/Edit';
import { SSOConfig as OrgSSOConfig } from 'features/organization/SSOConfig';
import { Summary as OrgSummary } from 'features/organization/Summary';
import { Tags as OrgTags } from 'features/organization/Tags';
import { Users as OrgUsers } from 'features/organization/Users';
import { AdminPermissions as SystemAdminPermissions } from 'features/system/AdminPermissions';
import { AtsConfigured as SystemAtsConfigured } from 'features/system/AtsConfigured';
import { AtsReport as SystemAtsReport } from 'features/system/AtsReport';
import { AtsSupported as SystemAtsSupported } from 'features/system/AtsSupported';
import { LoginHistory as SystemLoginHistory } from 'features/system/LoginHistory';
import { Monitor as SystemMonitor } from 'features/system/Monitor';
import { ExportFormats as SystemExportFormats } from 'features/system/ExportFormats';
import { EmailTracking as SystemEmailTracking } from 'features/system/EmailTracking';
import { LIProfiles as SystemLIProfiles } from 'features/system/LIProfiles';
import { SMBInvites as SystemSMBInvites } from 'features/system/SMBInvites';
import { SMBBlocklist as SystemSMBBlocklist } from 'features/system/SMBBlocklist';
import { SMBInactiveUsers as SystemSMBInactiveUsers } from 'features/system/SMBInactiveUsers';
import { Sku as SystemSku } from 'features/system/Sku';
import { SSOHistory as SystemSSOHistory } from 'features/system/SSOHistory';
import { ProfileKeys as SystemProfileKeys } from 'features/system/ProfileKeys';
import { DeleteUser as SystemDeleteUser } from 'features/system/DeleteUser';
import { Summary as SystemSummary } from 'features/system/Summary';
import { CreateOrganization as SystemCreateOrganization } from 'features/system/CreateOrganization';
import { Tooltips as SystemTooltips } from 'features/system/Tooltips';
import { AtsExportHistory as UserAtsExportHistory } from 'features/user/AtsExportHistory';
import { MaxioTransactionHistory as UserMaxioTransactionHistory } from 'features/user/MaxioTransactionHistory';
import { LoginHistory as UserLoginHistory } from 'features/user/LoginHistory';
import { Messaging as UserMessaging } from 'features/user/Messaging';
import { MessagingHistory as UserMessagingHistory } from 'features/user/MessagingHistory';
import { Monitor as UserMonitor } from 'features/user/Monitor';
import { QuotaHistory as UserQuotaHistory } from 'features/user/QuotaHistory';
import { SearchHistory as UserSearchHistory } from 'features/user/SearchHistory';
import { Settings as UserSettings } from 'features/user/Settings';
import { Edit as UserEdit } from 'features/user/Edit';
import { SSOHistory as UserSSOHistory } from 'features/user/SSOHistory';
import { SubscriptionHistory as UserSubscriptionHistory } from 'features/user/SubscriptionHistory';
import { Summary as UserSummary } from 'features/user/Summary';
import { Integrations as SystemIntegrations } from 'features/system/Integrations';
import { Integrations as OrganizationIntegrations } from 'features/organization/Integrations';

interface Tab {
  name: string;
  el: () => JSX.Element;
  tip?: string;
}

interface Category {
  name: string;
  sub: Tab[];
  tip?: string;
}

const contentMap: Record<LookupType, (Tab | Category)[]> = {
  SYSTEM: [
    { name: 'Summary', el: SystemSummary },
    { name: 'Organization', tip: 'Create a new organization', el: SystemCreateOrganization },
    {
      name: 'ATS',
      tip: 'Category: Applicant Tracking System (ATS) integrations',
      sub: [
        {
          name: 'Supported',
          tip: 'Supported ATS integrations',
          el: SystemAtsSupported,
        },
        {
          name: 'Configured',
          tip: 'Configured ATS integrations by organization',
          el: SystemAtsConfigured,
        },
        {
          name: 'Rediscovery Status',
          tip: 'Rediscovery (ATS download) status by organization',
          el: SystemAtsReport,
        },
      ],
    },
    {
      name: 'Users',
      tip: 'Category: Permissions and history for specific users',
      sub: [
        {
          name: 'Admin Permissions',
          tip: 'Grant permission to access SeekOut Admin',
          el: SystemAdminPermissions,
        },
        { name: 'Login History', tip: 'Login details by email address', el: SystemLoginHistory },
        {
          name: 'SSO History',
          tip: 'Single Sign On (SSO) auth details by email address',
          el: SystemSSOHistory,
        },
        {
          name: 'Profile Keys',
          tip: 'Get profile keys from any customer owned project',
          el: SystemProfileKeys,
        },
        {
          name: 'Delete Account',
          tip: 'Delete user entry from Accounts table',
          el: SystemDeleteUser,
        },
      ],
    },
    { name: 'Sku List', tip: 'View SKU details', el: SystemSku },
    {
      name: 'Tooltips',
      tip: 'View editable tooltips',
      el: SystemTooltips,
    },
    {
      name: 'Monitor',
      tip: 'View history of admin actions',
      el: SystemMonitor,
    },
    { name: 'Export Formats', tip: 'Custom export formats', el: SystemExportFormats },
    { name: 'Email Tracking', tip: 'Email Tracking Report', el: SystemEmailTracking },
    { name: 'LI Profiles', tip: 'Delete LinkedIn profiles', el: SystemLIProfiles },
    {
      name: 'SMB',
      tip: 'Category: SMB invites and domain list',
      sub: [
        {
          name: 'Invite links',
          tip: 'SMB Invite links',
          el: SystemSMBInvites,
        },
        {
          name: 'Domains',
          tip: 'List of SMB allowed and blocked domains',
          el: SystemSMBBlocklist,
        },
        {
          name: 'Inactive Users',
          tip: 'List of SMB users who have not activated the account',
          el: SystemSMBInactiveUsers,
        },
      ],
    },
    {
      name: 'Integrations',
      tip: 'Category: Integrations and configurations',
      el: SystemIntegrations,
    },
  ],
  Organization: [
    { name: 'Summary', el: OrgSummary },
    {
      name: 'Account',
      tip: 'Category: Configs, properties, and settings',
      sub: [
        { name: 'Settings', el: OrgSettings },
        { name: 'Edit', tip: 'Edit settings', el: OrgEdit },
        { name: 'SSO', tip: 'Single Sign On (SSO) configuration and settings', el: OrgSSOConfig },
        { name: 'Tags', tip: 'Candidate tag list', el: OrgTags },
        { name: 'Candidate Statuses', tip: 'Candidate status list', el: OrgCandidateStatuses },
        {
          name: 'Power Filters',
          tip: 'Custom, organization-wide power filter definitons',
          el: OrgPowerFilters,
        },
        { name: 'Export Formats', tip: 'Custom export formats', el: OrgExportFormats },
      ],
    },
    {
      name: 'Users',
      tip: 'Category: Member list, operations, and history',
      sub: [
        { name: 'Users', el: OrgUsers },
        { name: 'Create', tip: 'Create new users', el: OrgCreateUsers },
        {
          name: 'Bulk Edit License',
          tip: 'Migrate users from one license to another',
          el: OrgEditLicense,
        },
        {
          name: 'Bulk Edit Team',
          tip: 'Migrate users from one team to another',
          el: OrgEditTeam,
        },
        { name: 'Bulk Delete', tip: 'Delete users by email', el: OrgDeleteUsers },
        { name: 'Search History', tip: 'Organization-wide searches', el: OrgSearchHistory },
      ],
    },
    {
      name: 'Reports',
      tip: 'View reports and logs for this organization',
      sub: [
        { name: 'ATS', tip: 'Rediscovery (ATS download) status', el: OrgAtsReport },
        { name: 'ROI', tip: 'ROI reports', el: OrgRoiReport },
        { name: 'ATS Rediscovery', tip: 'ATS Rediscovery logs', el: OrgAtsRediscoveryLogs },
        {
          name: 'ATS Export History',
          tip: 'Candidates exported to Applicant Tracking System (ATS)',
          el: OrgAtsExportHistory,
        },
      ],
    },
    { name: 'ATS Connections', tip: 'ATS Connection configurations', el: OrgAtsConnections },

    { name: 'Monitor', tip: 'View history of admin actions for this organization', el: OrgMonitor },
    {
      name: 'Integrations',
      tip: 'View Integrations and configurations for this organization',
      el: OrganizationIntegrations,
    },
  ],
  User: [
    { name: 'Summary', el: UserSummary },
    {
      name: 'User Account',
      tip: 'Category: Configs, properties, and settings',
      sub: [
        { name: 'Settings', el: UserSettings },
        { name: 'Edit', tip: 'Edit settings', el: UserEdit },
        { name: 'Login History', tip: 'Login details', el: UserLoginHistory },
        { name: 'SSO History', tip: 'Single Sign On (SSO) auth details', el: UserSSOHistory },
        {
          name: 'Subscription History',
          tip: 'License applications and rollovers',
          el: UserSubscriptionHistory,
        },
        {
          name: 'Messaging Config',
          tip: 'Connected mailboxes',
          el: UserMessaging,
        },
      ],
    },
    {
      name: 'Activity',
      tip: 'Sourcing and Messaging user activity',
      sub: [
        { name: 'Search History', tip: 'User searches', el: UserSearchHistory },
        {
          name: 'Messaging History',
          tip: 'Messaging activity by activity type',
          el: UserMessagingHistory,
        },
        { name: 'Quota History', tip: 'Quota usage, addons, and rollovers', el: UserQuotaHistory },
        {
          name: 'ATS Export History',
          tip: 'Candidates exported to Applicant Tracking System (ATS)',
          el: UserAtsExportHistory,
        },
        {
          name: 'Maxio Transaction History',
          tip: 'Users Maxio transaction history',
          el: UserMaxioTransactionHistory,
        },
      ],
    },
    { name: 'Monitor', tip: 'View history of admin actions for this user', el: UserMonitor },
  ],
  NewUser: [
    { name: 'Summary', el: NewUserSummary },
    { name: 'Settings', el: NewUserSettings },
    { name: 'Login History', el: NewUserLoginHistory },
    { name: 'SSO History', el: NewUserSSOHistory },
  ],
};

const isTab = (tab: Category | Tab): tab is Tab => (tab as Tab).el !== undefined;
const getSub = (cat: Category | Tab) => (isTab(cat) ? [] : cat.sub);

/**
 * Get the content tab name lists and tooltips
 * @param lookup The current lookup
 * @returns The name and tooltip lists for the tabs and subtabs
 */
export function getTabStrings(lookup: Lookup): {
  names: string[];
  tips: string[];
  subNames: string[];
  subTips: string[];
} {
  if (!lookup.type) {
    return { names: ['Loading'], subNames: [], tips: [''], subTips: [] };
  }

  const tabMap = contentMap[lookup.type];
  const secondary = tabMap[lookup.contentIndex ?? 0];

  return {
    names: tabMap.map(({ name }) => name),
    tips: tabMap.map(({ tip }) => tip ?? ''),
    subNames: getSub(secondary).map(({ name }) => name),
    subTips: getSub(secondary).map(({ tip }) => tip ?? ''),
  };
}

/**
 * Get the View component for a lookup's content selections
 * @param lookup The current lookup
 * @returns The features/views component
 */
export function getView(lookup: Lookup): JSX.Element {
  if (!lookup.type) {
    return <></>;
  }

  const index = lookup.contentIndex ?? 0;
  const subIndex = lookup.contentSubIndices?.[index] ?? 0;
  const tab = contentMap[lookup.type][index];

  if (!tab) {
    return <></>;
  }

  const MyComponent = isTab(tab) ? tab.el : tab.sub[subIndex].el;

  return <MyComponent />;
}
