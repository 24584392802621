import { Box, Button, Typography, styled } from '@mui/material';
import { createInviteLinks } from 'api/apiThunks';
import { NoPermission } from 'components/NoPermission';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { ViewFrame } from 'features/frame/ViewFrame';
import { ChangeEvent, useState } from 'react';
import { Privilege } from 'types/enums';
import { createFailedOperation } from 'utils/apiResult';
import { uploadCsvFile } from 'utils/files';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';

const Input = styled('input')({ display: 'none' });
const Label = styled('label')({ alignSelf: 'center' });

type Row = Record<string, string>;
const emailsPerChunk = 10;

/**
 * The system smb invites view
 */
export const SMBInvites = (): JSX.Element => {
  const [operationType, setOperationType] = useState<SystemOpName>();
  const [operations, setOperations] = useState<Operation[]>([]);
  const [uploadOperations, setUploadOperations] = useState<Operation[]>([]);
  const [csvHasEmailColumn, setCsvHasEmailColumn] = useState(true);
  const [csvEmails, setCsvEmails] = useState<string[]>([]);
  const [invites, setInvites] = useState<SMBInviteDef[]>([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState('Are you sure?');

  const contentOperations = [...uploadOperations, ...operations];
  const adminPrivilege = usePrivilegeSelector();

  const onUploadOperation = (ops: Operation[]) => {
    // If a single successful operation, then translate to [] (no operation)
    ops = ops.length === 1 && ops[0]?.status === 'succeeded' ? [] : ops;
    setUploadOperations(ops);
  };

  const onUploadSuccess = (newColumns: string[], newRows: Row[]) => {
    const emailColumn = newColumns.find((c) => c.includes('Email'));
    if (!emailColumn) {
      setCsvHasEmailColumn(false);
      return;
    }
    let emails = newRows.map((r) => r[emailColumn]?.trim());
    emails = Array.from(new Set(emails));
    setCsvEmails(emails);
    setCsvHasEmailColumn(true);
    setConfirmModalTitle(`Create invite links for ${emails.length} email addresses?`);
    setConfirmModalOpen(true);
    (document.getElementById('contained-button-file') as HTMLInputElement).value = '';
  };

  const onSubmit = async () => {
    setConfirmModalOpen(false);
    setOperationType('createInviteLinks');
    setOperations([{ status: 'loading' }]);
    try {
      for (let i = 0; i < csvEmails.length; ) {
        const chunk = csvEmails.slice(i, i + emailsPerChunk);
        const results = await createInviteLinks(chunk);
        setInvites((invites) => [...invites, ...results]);
        i += emailsPerChunk;
      }
      setOperationType(undefined);
      setOperations([]);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Create Invite links: ${message}`)]);
    }
  };

  if (adminPrivilege !== Privilege.All) {
    return (
      <ViewFrame>
        <NoPermission />
      </ViewFrame>
    );
  }

  return (
    <ViewFrame
      header={
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Label htmlFor="contained-button-file">
              <Input
                accept="text/csv"
                id="contained-button-file"
                type="file"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  uploadCsvFile(e, onUploadOperation, onUploadSuccess)
                }
              />
              <Button variant="contained" component="span" sx={{ minWidth: 125 }}>
                Upload CSV
              </Button>
            </Label>
          </Box>
          {!csvHasEmailColumn && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              Could not process the file. The CSV file must have an Email column in it.
            </Typography>
          )}
        </>
      }
      contentLoader={{
        message: 'Creating Invite links',
        contentOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
    >
      <StandardGrid
        dataSet={invites}
        tipModel="NewUser"
        getRowId={(x) => x.email ?? 'placeholder'}
        filterPlaceholder="Filter the displayed users"
        cols={[
          {
            name: 'Email',
            valueProperty: 'email',
            type: 'email',
          },
          {
            name: 'Status',
            valueProperty: 'status',
          },
          {
            name: 'Invite Link',
            valueProperty: 'inviteLink',
            relativeWidth: 3,
          },
        ]}
      />
      <ConfirmModal
        open={confirmModalOpen}
        prompt={confirmModalTitle}
        onClose={() => setConfirmModalOpen(false)}
        onAccept={onSubmit}
      />
    </ViewFrame>
  );
};
