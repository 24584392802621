import { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ViewFrame } from 'features/frame/ViewFrame';
import { DatabricksHistory } from 'components/integrations/DatabricksHistory';
import {
  getServiceBusMetrics,
  getDatabricksHistory,
  deleteDatabricksEntry,
  flipProcessFlag,
} from 'api/apiThunks';
import { createFailedOperation } from 'utils/apiResult';

interface Props {
  connectionId?: string;
  atsType: string;
}

type DriverOpName = 'getDatabricksHistory' | 'getServiceBusMetrics' | undefined;

export const Driver = ({ connectionId, atsType }: Props): JSX.Element => {
  const [metrics, setMetrics] = useState<IServiceBusMetrics | null>(null);
  const [history, setHistory] = useState<ITransformationJobRuns[] | null>(null);
  const [operations, setOperations] = useState<Operation[]>([]);
  const [operationType, setOperationType] = useState<DriverOpName>();
  const [action, setAction] = useState<string | undefined>();

  let contentMessage;
  switch (operationType) {
    case 'getDatabricksHistory':
      contentMessage = 'Fetching Databricks history';
      break;
    case 'getServiceBusMetrics':
      contentMessage = 'Fetching ServiceBusMetrics';
      break;
    default:
      contentMessage = '';
      break;
  }

  useEffect(() => {
    if (connectionId) {
      fetchDatabricksHistory(connectionId);
      fetchMetrics(connectionId, atsType);
    }
  }, [connectionId, atsType]);

  const fetchMetrics = async (connectionId: string, atsType: string): Promise<void> => {
    setOperationType('getServiceBusMetrics');
    setAction('fetch');
    setOperations([{ status: 'loading' }]);

    try {
      const response = await getServiceBusMetrics(connectionId, atsType);
      setAction(undefined);
      setOperations([]);
      setMetrics(response);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([
        createFailedOperation(
          `Error in fetching ServiceBusMetrics for ${connectionId}: ${message}`
        ),
      ]);
    }
  };

  const fetchDatabricksHistory = async (connectionId: string): Promise<void> => {
    setOperationType('getDatabricksHistory');
    setAction('fetch');
    setOperations([{ status: 'loading' }]);

    try {
      const response = await getDatabricksHistory(connectionId);
      setAction(undefined);
      setOperations([]);
      setHistory(response);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Error fetching Databricks History: ${message}`)]);
      setAction(undefined);
      setHistory(null);
    }
  };

  const handleDeleteEntry = async (jobRunId: string): Promise<void> => {
    setAction('fetch');
    setOperations([{ status: 'loading' }]);

    try {
      if (connectionId) {
        await deleteDatabricksEntry(connectionId, jobRunId);
        setAction(undefined);
        setOperations([]);
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Error while deleting entry: ${message}`)]);
    }
  };

  const handleFlipProcessFlag = async (connectionId?: string): Promise<void> => {
    setAction('fetch');
    setOperations([{ status: 'loading' }]);

    try {
      if (connectionId) {
        await flipProcessFlag(connectionId, 'processFlag');
        setAction(undefined);
        setOperations([]);
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Error flipping process flag: ${message}`)]);
    }
  };

  const isProcessing = !!action || !!operations.length;

  return (
    <ViewFrame
      contentLoader={{
        message: contentMessage || '',
        contentOperations: operations,
        forceClose: !isProcessing,
        onClose: () => {
          setAction('');
          setOperations([]);
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2, mr: 2 }}>
          <Typography variant="h5" sx={{ mb: 2, alignSelf: 'flex-start' }}>
            Service Bus Metrics
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 1,
              mb: 1,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1, mb: 1 }}>
              <Typography>
                <strong>Topic Message Count:</strong>
              </Typography>
              <Typography>{metrics?.ActiveMessageCount}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1, mb: 1 }}>
              <Typography>
                <strong>Deadletter Message Count:</strong>
              </Typography>
              <Typography>{metrics?.DeadLetterMessageCount}</Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleFlipProcessFlag(connectionId)}
              sx={{ mt: 1, alignSelf: 'flex-start' }}
            >
              Flip Process Flag
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            flex: 2,
            ml: 1,
            mr: 2,
            border: '1px solid #ccc',
            height: '50vh',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {history ? (
            <>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Databricks Recent History
              </Typography>
              {history && <DatabricksHistory history={history} onDelete={handleDeleteEntry} />}
            </>
          ) : (
            'No Rows'
          )}
        </Box>
      </Box>
    </ViewFrame>
  );
};
